import Tab from "react-bootstrap/Tab";
import React, { useContext, useEffect, useState } from "react";



import {PostComponentToken, PostComponentTokenDelete, PostWebxLaravel} from "../../PostCompnent";
import {toast, ToastContainer} from "react-toastify";



export function CardInformationData(props) {

    const [currency,setCurrencyData]=useState('');
    const [secureUrl,secure3dResponseURL]=useState('');
    const [bankMid,setBankMid]=useState('');
    const [randomString,setRandomString]=useState('');
    const [tokenData,setTokenData]=useState('');
    const [businessData,setBusinessData]=useState('');
    const [cardData,setCardData]=useState([]);
    const [cardList,setCardList]=useState('');
    useEffect(() => {
        getTokenData();
    }, []);



    function popupWindow(url, windowName, win, w, h) {
        const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
        const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
        return win.open(
            url,
            windowName,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        );
    }

   const getTokenData = async () => {
        sessionStorage.setItem("session_gererate","0")
        let formDatas=new FormData();
        PostWebxLaravel("get-auth-token-bpsp", formDatas).then(response => {
            getSavedCards(response.data.token)

            setTokenData(response.data?response.data.token:'')
            setCurrencyData(response.data?response.data.currency:'')
            secure3dResponseURL(response.data?response.data.secure3dResponseURL:'')
            setBankMid(response.data?response.data.bankMID:'')
            setRandomString(response.data?response.data.reference:'')

        });
    }

    function setDefaultCard(cardId,card_merchant_id){

        let formData=new FormData()
        formData.append("card_id",cardId)
        formData.append("card_merchant_id",card_merchant_id)
        PostWebxLaravel("token/set-default-subscription-card", formData).then(
            (response) => {

                getSavedCards();

            }
        );
    }

    function onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    function getSavedCards(token_datas){

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + ' ' + props.tokenData
                },

            };


            let formData=new FormData()
            formData.append("id",sessionStorage.getItem("merchant_id"))
            formData.append("email",sessionStorage.getItem("user_email"))

            PostWebxLaravel("token/retrive-card", formData).then(
                (response) => {

                    const zeroPad = (num, places) => String(num).padStart(places, '0');
                    const trans = response.data.get_save_card_details && response.data.get_save_card_details.length ? (response.data.get_save_card_details.map((list, key) =>
                        <tr key={key}>
                            <td>{zeroPad(key + 1, 3)}</td>
                            <td>{list.card_first}******{list.card_last}</td>
                            <td>
                                {
                                    list.default_card==1?(
                                        <span style={{padding: '8px', borderRadius: 'inherit'}} className="badge badge-pill badge-success" >Default</span>
                                    ):(
                                        <button className="btn dot-drop" onClick={() => setDefaultCard(list.id,response.data.id)}>
                                            <span className="badge badge-pill badge-light">Set as Default</span>
                                        </button>
                                    )
                                }
                            </td>
                            <td>
                                <button className="btn dot-drop" onClick={() => deleteCard(list.card_id,list.id,token_datas)}>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    )) : (
                        ''
                    )

                    setCardList(trans)
                }
            );
    }


    function deleteCard(cardId,list_id,token_datas){

        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' +token_datas
            },
            method: 'delete',

        };

        let data = JSON.stringify({
            "cardId": cardId,
            "customerId": sessionStorage.getItem("merchant_id"),
            "customerEmail": sessionStorage.getItem("user_email")
        });


        PostComponentTokenDelete("cards", data, config).then(response => {

                console.log(response)
            if(response.data.success==true){
                deleteCardRecord(list_id)


                toast("Card Deleted Successfully")
                getSavedCards();

            }else{
                    if (response.data.error==true){
                        if(response.data.type=="card_not_found"){
                            deleteCardRecord(list_id)
                            getSavedCards();
                        }
                    }
                toast("Card deletion unsuccessful")
            }
        });
    }

    function deleteCardRecord(cardId){

        let formData=new FormData()
        formData.append("card_id",cardId)
        PostWebxLaravel("token/delete-token-card", formData).then(
            (response) => {


            }
        );
    }

    let lend = 0;
    return (
        <Tab.Pane eventKey="second">
            <ToastContainer />
            <div className="row pay-opts">
                <div className="col-12">

                </div>


                <div className="col-12">
                    <div className="tab-content" id="nav-tabContent">

                        <div
                            className="tab-pane fade show active"
                            id="pay-online"
                            role="tabpanel"
                            aria-labelledby="nav-pay-online-tab"
                        >




                                    <div className="row row_clr ">
                                        <div class="col-12 fm-in">

                                            <div className="row administrator-information">
                                                <div className="col-12">


                                                    <div className="row row_clr ">
                                                        <div className="col-12 fm-in">
                                                            <p className="p-us"><img src="images/cards/4.png" alt=""/>Pay Using Saved
                                                                Cards <img
                                                                    src="images/cards/13.png" alt=""/></p>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                <tr>
                                                                    <th>Card Number</th>
                                                                    <th>Default Card</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                            {cardList}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="row row_clr ">
                                                        <div className="col-12 fm-in">
                                                            {/*<p className="p-us">*/}
                                                            {/*      You may proceed to make payment by selecting the 'Pay with Visa' option or opt out by selecting the 'Pay Later' option and make an payment at your convenience.*/}
                                                            {/*</p>*/}
                                                            {/* <ul class="clist">
                        <li>
                          <img
                            src={require("../../../../images/cards/1.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/2.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/3.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/4.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/5.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/6.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/7.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/8.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/9.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/10.png")}
                            alt=""
                          />
                        </li>
                      </ul> */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/*<p className="p-us">*/}
                                            {/*      You may proceed to make payment by selecting the 'Pay with Visa' option or opt out by selecting the 'Pay Later' option and make an payment at your convenience.*/}
                                            {/*</p>*/}
                                            {/* <ul class="clist">
                        <li>
                          <img
                            src={require("../../../../images/cards/1.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/2.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/3.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/4.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/5.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/6.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/7.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/8.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/9.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/10.png")}
                            alt=""
                          />
                        </li>
                      </ul> */}
                                        </div>
                                    </div>



                        </div>
                        <div
                            className="tab-pane fade"
                            id="pay-offline"
                            role="tabpanel"
                            aria-labelledby="nav-pay-offline-tab"
                        >
                            <div className="row offline-pay">
                                <div className="col-12 oft">
                                    <h4>For Cheque Payments</h4>
                                </div>
                                <div className="col-md-6">
                                    <p>Please make the cheque in favor of</p>
                                    <h3>WEBXPAY (Pvt) Ltd.</h3>
                                </div>
                                <div className="col-md-6">
                                    <p>Send the cheque via Registered Post to</p>
                                    <p>
                                        <b>
                                            5th Floor, Green Lanka Tower, No. 46/46, Navam Mawatha,
                                            Colombo 02, Sri Lanka.
                                        </b>
                                    </p>
                                    <p>
                                        <b>
                                            Or <a href="tel:+94117441900">Call (+94) 117 441 900</a>{" "}
                                            to Collect
                                        </b>
                                    </p>
                                </div>
                                <div className="col-12 oft mt-4">
                                    <h4>For Direct Debits or Wire Transfers</h4>
                                    <p className="mb-3">
                                        Please find the account information as follows:
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <h3>1007 5396 0039</h3>
                                    <h4 className="mb-2">WEBXPAY (Pvt) Ltd.</h4>
                                    <p>
                                        5th Floor, Green Lanka Tower,
                                        <br />
                                        No. 46/46, Navam Mawatha,
                                        <br />
                                        Colombo 02, Sri Lanka.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <ul className="b-list">
                                        <li>
                                            <span>Bank Name</span>: Sampath Bank PLC
                                        </li>
                                        <li>
                                            <span>Address</span>: No. 19 A1, Kandy
                                        </li>
                                        <li>
                                            <span>Bank Code</span>: 7278
                                        </li>
                                        <li>
                                            <span>Branch No</span>: 007
                                        </li>
                                        <li>
                                            <span>SWIFT Code</span>: BSAMLKLX
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 oft mt-4">
                                    <h4>Proof of Payment</h4>
                                    <div className="upload">
                                        <img src="images/upload.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    );
}
