import React, {PureComponent} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {Navigation} from "./Navigation";

import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import ReactPaginate from "react-paginate";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import {GetWebxLaravel, PostWebxLaravel} from "./PostCompnent";
import {Footer} from "./Footer";
import {toast} from "react-toastify";
import {SupportButton} from "./SupportButton";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export class SettingsInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bank_name: '',
            settlement_status: '',
            modalShowTax: '',
            setModalShowTax: '',
            additional_charge_lists: [],
            additional_charge_filters: [],
            additional_charge_lists_render: [],
            offsetAdditionalCharge: 0,
            perPageAdditionalCharge: 10,
            currentPageAdditionalCharge: 0,
            pageCountAdditionalCharge: 0,
            //terms_conditions
            terms_conditions_lists: [],
            terms_conditions_filters: [],
            terms_conditions_render: [],
            offsetTermsConditions: 0,
            perPageTermsConditions: 10,
            currentPageTermsConditions: 0,
            pageCountTermsConditions: 0,
            terms_condition_id: '',
            //
            error_message: '',
            errors_additional_charge: {},
            description: '',
            type: '',
            value: '',
            charge_id: '',
            text_additional: 'Processing...',
            manageAdditionalCharge: this.saveNewAdditionalCharges,
            manageTermsConditions: this.saveTermsConditions,
            terms_conditions: '',
            save_status: true,
            update_status: false,
            model: '',
        };

        this.saveNewAdditionalCharges = this.saveNewAdditionalCharges.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.showModalGroup = this.showModalGroup.bind(this);
        this.closeModalGroup = this.closeModalGroup.bind(this);
        this.getAdditionalCharge = this.getAdditionalCharge.bind(this);
        this.loadTableAdditionalCharge = this.loadTableAdditionalCharge.bind(this);
        this.handlePageClickAdditionalCharges = this.handlePageClickAdditionalCharges.bind(this);
        this.handlePageClickTerms = this.handlePageClickTerms.bind(this);

        this.handleValidationAdditionalCharge = this.handleValidationAdditionalCharge.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.editAdditionalCharge = this.editAdditionalCharge.bind(this);
        this.saveTermsConditions = this.saveTermsConditions.bind(this);
        this.editTermsConditions = this.editTermsConditions.bind(this);
        this.fetchTermsConditions = this.fetchTermsConditions.bind(this);
        this.loadTableTermsConditions = this.loadTableTermsConditions.bind(this);
        this.getSingleTerm = this.getSingleTerm.bind(this);
        this.cancelEditTerms = this.cancelEditTerms.bind(this);
        this.deleteTermsConditions = this.deleteTermsConditions.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);

    }
    onEditorStateChange (terms_conditions)  {
       /* this.setState({
            terms_conditions,
        });*/
    }

    //Terms conditions
    deleteTermsConditions(id) {
        let formData = new FormData();    //formdata object
        formData.append('id', id);
        formData.append('_method', "DELETE");
        this.handleToggleProgress();
        PostWebxLaravel("terms/delete_terms/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id") + '|' + id, formData).then(response => {
            this.handleCloseProgress();
            this.fetchTermsConditions();
        });
    }

    saveTermsConditions() {

        this.setState({
            error_message: ''
        });

        let formData = new FormData();    //formdata object

        let terms_conditions = this.state.terms_conditions;


        formData.append('terms_condition_text', terms_conditions);   //append the values with key, value pair
        formData.append('merchant_id', sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id"));
        this.handleToggleProgress();
        PostWebxLaravel("terms/create_terms", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                terms_conditions: '',
            });
            this.fetchTermsConditions();
        });

    }

    getSingleTerm(id, terms_conditions) {
        this.setState({
            update_status: true,
            save_status: false,
            terms_conditions: terms_conditions,
            terms_condition_id: id
        });
    }

    cancelEditTerms() {
        this.setState({
            update_status: false,
            save_status: true,
            terms_conditions: '',

        });
    }

    editTermsConditions() {
        this.setState({
            error_message: ''
        });
        let formData = new FormData();    //formdata object

        let terms_conditions = this.state.terms_conditions;
        let merchant_id = 64;
        let terms_condition_id = this.state.terms_condition_id;

        formData.append('terms_condition_text', terms_conditions);   //append the values with key, value pair
        formData.append('_method', 'put');
        this.handleToggleProgress();
        PostWebxLaravel("terms/update_terms/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id") + '|' + terms_condition_id, formData).then(response => {
            this.handleCloseProgress();
            if (response.status == 200) {
                this.setState({
                    terms_conditions: '',
                    update_status: false,
                    save_status: true
                })
            }
            this.fetchTermsConditions();
        });

    }

    fetchTermsConditions() {
        this.handleToggleProgress();
        GetWebxLaravel("terms/get_terms/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();
            this.setState({
                terms_conditions_lists: response.data,
                terms_conditions_filters: response.data
            }, () => {
                this.loadTableTermsConditions();
            });
        });
    }

    loadTableTermsConditions() {

        const list_data = this.state.terms_conditions_filters;
        const zeroPad = (num, places) => String(num).padStart(places, '0');

        const slice = list_data.slice(this.state.offsetTermsConditions, this.state.offsetTermsConditions + this.state.perPageTermsConditions);
        const trans = list_data && list_data.length ? (slice.map((list, key) =>
            <tr className="">
                <td className=""
                    data-title="Terms & Conditions">

                    <div dangerouslySetInnerHTML={{ __html: list.terms_condition_text }}  />

                </td>
                <td>
                    <button className="btn dot-drop mr-2"
                            onClick={() => this.getSingleTerm(list.id, list.terms_condition_text)}>
                        <i className="fa fa-pencil"
                           aria-hidden="true"></i>
                    </button>

                    <button className="btn dot-drop" onClick={() => this.deleteTermsConditions(list.id)}>
                        <i className="fa fa-trash"
                           aria-hidden="true"></i>
                    </button>
                </td>
            </tr>
        )) : (
            <tr className=''>
                <td colSpan="4" className='' data-title='#'>No Results Found</td>
            </tr>
        );
        this.setState({

            pageCountTermsConditions: Math.ceil(list_data.length / this.state.perPageTermsConditions),
            terms_conditions_render: trans
        })
    }

    handlePageClickTerms(e) {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageTermsConditions;
        this.setState({
            currentPageTermsConditions: selectedPage,
            offsetTermsConditions: offset
        }, () => {
            this.loadTableTermsConditions();
        });
    }

    //Additional Charge
    handleValidationAdditionalCharge() {
        let errors_cus = {};
        let formIsValid = true;
        //Email
        if (this.state.description == "") {
            formIsValid = false;
            errors_cus["description"] = "Cannot be empty";
        }
        if (this.state.type == "") {
            formIsValid = false;
            errors_cus["type"] = "Cannot be empty";
        }
        if (this.state.value == "") {
            formIsValid = false;
            errors_cus["value"] = "Cannot be empty";
        }

        this.setState({errors_additional_charge: errors_cus});
        return formIsValid;
    }

    openEditModal(charge_id, description, type, value) {

        this.setState({
            text_additional: 'Edit Charges',
            modalShowTex: true,
            setModalShowTex: true,
            charge_id: charge_id,
            description: description,
            type: type,
            value: value,
            manageAdditionalCharge: this.editAdditionalCharge,
        }, () => {
            var modelD = document.getElementById("activation-modal");
            modelD.classList.add("ancd-mod");
        });
    }

    saveNewAdditionalCharges() {
        if (this.handleValidationAdditionalCharge()) {
            this.setState({
                error_message: ''
            });
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': sessionStorage.getItem("laravel_token"),

                },
            };
            let formData = new FormData();    //formdata object

            let description = this.state.description;
            let type = this.state.type;
            let value = this.state.value;
            let merchant_id = sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id");

            formData.append('description', description);   //append the values with key, value pair
            formData.append('type', type);
            formData.append('value', value);
            formData.append('merchant_id', merchant_id);
            this.handleToggleProgress();
            PostWebxLaravel("additional_charges/create_charge", formData).then(response => {
                this.handleCloseProgress();
                let response_data = response.data;
                if (response_data.success == 200) {
                    this.getAdditionalCharge(merchant_id);
                    this.closeModalGroup();
                } else {
                    this.setState({
                        error_message: response_data.message
                    });
                }
            });
        }
    }

    editAdditionalCharge() {
        this.setState({
            error_message: ''
        });

        if (this.handleValidationAdditionalCharge()) {

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': sessionStorage.getItem("laravel_token"),

                },
            };
            let formData = new FormData();    //formdata object

            let description = this.state.description;
            let type = this.state.type;
            let charge_id = this.state.charge_id;
            let value = this.state.value;
            let merchant_id = 64;

            formData.append('description', description);   //append the values with key, value pair
            formData.append('type', type);
            formData.append('value', value);
            formData.append('merchant_id', merchant_id);
            formData.append('_method', 'put');
            this.handleToggleProgress();
            PostWebxLaravel("additional_charges/update_charge/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id") + '|' + charge_id, formData).then(response => {
                this.handleCloseProgress();
                if (response.status == 200) {
                    this.getAdditionalCharge(merchant_id);
                    this.closeModalGroup();
                    this.setState({
                        description: '',
                        type: '',
                        value: ''
                    })
                }
            });

        }
    }

    getAdditionalCharge(merchant_id) {
        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),

            },
        };
        this.handleToggleProgress();
        GetWebxLaravel("additional_charges/get_charges/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();
            this.setState({
                additional_charge_lists: response.data,
                additional_charge_filters: response.data
            }, () => {
                this.loadTableAdditionalCharge();
            });
        });
    }

    handlePageClickAdditionalCharges = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageAdditionalCharge;
        this.setState({
            currentPageAdditionalCharge: selectedPage,
            offsetAdditionalCharge: offset
        }, () => {
            this.loadTableAdditionalCharge();
        });

    };

    loadTableAdditionalCharge() {
        const list_data = this.state.additional_charge_filters;
        const zeroPad = (num, places) => String(num).padStart(places, '0');

        const slice = list_data.slice(this.state.offsetAdditionalCharge, this.state.offsetAdditionalCharge + this.state.perPageAdditionalCharge);
        const trans = list_data && list_data.length ? (slice.map((list, key) =>
            <tr className="">
                <td className="" data-title="#">{key + 1}</td>
                <td className=""
                    data-title="Tax/Charge Name">{list.description}
                </td>
                <td className="" data-title="Type">{list.type == "F" ? "Fixed Price" : "Percentage"}</td>
                <td className="" data-title="Type">{list.value} {list.type == "F" ? "LKR" : "%"}</td>

                <td className="" data-title=""
                    style={{textAlign: "center"}}>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle dot-drop"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => this.openEditModal(list.id, list.description, list.type, list.value)}
                        >
                            <i className="fas fa-edit"
                               aria-hidden="true"></i>
                        </button>
                        <div
                            className="dropdown-menu table-drop"
                            aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#edit-tax-modal"
                            >
                                <i className="fas fa-pencil-alt"
                                   aria-hidden="true"></i>
                            </button>
                            <button className="dropdown-item"><i
                                className="fas fa-trash-alt"
                                aria-hidden="true"></i></button>

                        </div>
                    </div>
                </td>
            </tr>
        )) : (
            <tr className=''>
                <td colSpan="4" className='' data-title='#'>No Results Found</td>
            </tr>
        );
        this.setState({
            pageCountAdditionalCharge: Math.ceil(list_data.length / this.state.perPageAdditionalCharge),
            additional_charge_lists_render: trans
        })

    }

    showModalGroup() {
        this.setState({
            text_additional: 'Save',
            modalShowTex: true,
            setModalShowTex: true,
            manageAdditionalCharge: this.saveNewAdditionalCharges,
            description: '',
            type: '',
            value: ''
        }, () => {
            var modelD = document.getElementById("activation-modal");
            modelD.classList.add("ancd-mod");
        });
    }

    closeModalGroup() {
        this.setState({
            modalShowTex: false,
            setModalShowTex: false,
        });
    }

    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleChangeText(content){
      this.setState({
          terms_conditions:content
      })
    }

    onChangeNumber(e) {
        const regex = /^[0-9\b]+$/;
        const value = e.target.value;

        if (value === '' || regex.test(value)) {
            this.setState({[e.target.name]: value})
        }
    }


    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    }

    componentWillMount() {

        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");
            this.getAdditionalCharge();
            this.fetchTermsConditions();

            this.setState({userToken: userToken});
        } else {
            this.setState({redirect: true});
        }
    }


    render() {

        const additional_charge_lists = this.state.additional_charge_lists_render;
        const terms_conditions_lists = this.state.terms_conditions_render

        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>
                <SupportButton/>
                {/*Tax Modal*/}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                    show={this.state.modalShowTex}
                    onHide={this.state.setModalShowTex}
                >

                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close"
                             onClick={this.closeModalGroup}>X
                        </div>
                        <div className="container-fluid p-4">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">Add New Additional Charge</h3>

                                    <div className="col-12 tdm-in p-0">

                                        <h6>Description </h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="description"
                                                   placeholder="Enter Tax/Charge Name" onChange={this.onChanges}
                                                   value={this.state.description}/>
                                            <span
                                                style={{color: "red"}}>{this.state.errors_additional_charge["description"]}</span>
                                        </div>

                                        <div className="col-12  p-0">
                                            <h6>Type of Addition</h6>
                                            <div className="form-group">
                                                <select name="type" id="" value={this.state.type}
                                                        className="form-control" onChange={this.onChanges}>
                                                    <option value=""> --Select Addition--</option>
                                                    <option value="P">Percentage</option>
                                                    <option value="F">Fixed</option>
                                                </select>
                                                <span
                                                    style={{color: "red"}}>{this.state.errors_additional_charge["type"]}</span>
                                            </div>
                                        </div>

                                        <div className="col-12  p-0">
                                            <h6>Addition Value</h6>
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="value"
                                                       placeholder="Enter Amount / Percentage"
                                                       onChange={this.onChangeNumber} value={this.state.value}/>
                                                <span
                                                    style={{color: "red"}}>{this.state.errors_additional_charge["value"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{color: "red"}}>{this.state.error_message}</p>
                                    <button type="button" className="nxt-btn"
                                            onClick={this.state.manageAdditionalCharge}>{this.state.text_additional} &nbsp;&nbsp;
                                        <i
                                            className="fas fa-save" aria-hidden="true"></i></button>
                                    {/*<a href="#" className="help"><i className="far fa-life-ring"
                                                                    aria-hidden="true"></i> Need Help with the
                                        System?</a>*/}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <Navigation pathname="settings-general"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="settings-general"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <Link className="nav-link" id="tt-tab" to="../settings-general"><span
                                        className="tt">Business Configuration</span><span className="amt">General</span></Link>
                                </li>
                                {/*   <li className="nav-item">
                                    <Link className="nav-link" id="tr-tab" to="../settings-currency"><span className="tt">Currency & Exchange Rates</span><span
                                        className="amt">Currency</span></Link>
                                </li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link active" id="tp-tab" to="../invoice-settings"><span
                                        className="tt">Invoices, Payment Links & Taxes</span><span
                                        className="amt">Invoices</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" to="../settings-user"><span
                                        className="tt">Roles, Profiles, and Permissions</span><span
                                        className="amt">User Accounts</span></Link>
                                </li>

                               {/* <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" to="../subscription-settings">
                                        <span className="tt">Payments, History, Upcoming Payments and Package Change</span>
                                        <span className="amt">Subscriptions</span>
                                    </Link>
                                </li>*/}
                            </ul>


                            <div className="row row_clr settings-outer">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                                    <div className="col-12 lft-sd pt-0">
                                        <Nav variant="pills" className="nav nav-tabs" id="set-tab1">
                                            <Nav.Item>
                                                <Nav.Link eventKey="one" className="nav-link">Additional
                                                    Charges</Nav.Link>
                                            </Nav.Item>
                                            {/*  <Nav.Item>
                                                <Nav.Link eventKey="two" className="nav-link">Emails &
                                                    Notifications</Nav.Link>
                                            </Nav.Item>*/}
                                            <Nav.Item>
                                                <Nav.Link eventKey="three" className="nav-link">Terms &
                                                    Conditions</Nav.Link>
                                            </Nav.Item>


                                        </Nav>
                                    </div>

                                    <div className="col-12 rht-sd">
                                        <div className="tab-content">
                                            <Tab.Pane eventKey="one" id="v-pills-1">
                                                <div className="tab-pane fade show active" id="to" role="tabpanel"
                                                     aria-labelledby="to-tab">
                                                    <div className="row row_clr">
                                                        <div className="col-12">
                                                            <h3 className="tdh">Additional Charges</h3>
                                                            <h4 className="sth">Manage Additional Charges</h4>
                                                            <button type="button" className="nxt-btn" style={{float: "left",
                                                                marginTop: "-5px",
                                                                marginBottom: "20px"}}   onClick={this.showModalGroup}>Add New Additional Charge &nbsp;&nbsp; <i
                                                                className="fas fa-plus-circle"
                                                                aria-hidden="true"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize: "20px"
                                                                    ,transform: "translateY(1px)"
                                                                }}
                                                                data-toggle="modal"
                                                                data-target="#add-new-tax-modal"
                                                            ></i></button>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="rg-container">
                                                                <table className="transactions-table" summary="Hed">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="">#</th>
                                                                        <th className="">Tax/Charge Name</th>
                                                                        <th className="">Type</th>
                                                                        <th className="">Rate</th>
                                                                        <th></th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {additional_charge_lists}
                                                                    </tbody>
                                                                </table>
                                                                <ReactPaginate
                                                                    previousLabel={"PREV"}
                                                                    nextLabel={"NEXT"}
                                                                    breakLabel={"..."}
                                                                    breakClassName={"break-me"}
                                                                    pageCount={this.state.pageCountAdditionalCharge}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={this.handlePageClickAdditionalCharges}
                                                                    containerClassName={"pagination"}
                                                                    subContainerClassName={"pages pagination"}
                                                                    activeClassName={"active"}/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="two" id="v-pills-1">
                                                <div className="tab-pane fade show active" id="tr" role="tabpanel"
                                                     aria-labelledby="tr-tab">
                                                    <div className="row row_clr">
                                                        <div className="col-12">
                                                            <h3 className="tdh">Emails & Notifications</h3>
                                                            <h4 className="sth mb">Payment Reminders</h4>
                                                            <p className="mp">Edit the payment reminder message that is
                                                                sent out to your customers for unsettled invoices and
                                                                payment links here.</p>
                                                        </div>

                                                        <div className="col-12">
                                                            <h6 className="sh">Available Variables</h6>
                                                            <p className="mp mpt2">
                                                                <b>{/*{{transaction_id}} {{customer_name}} {{payment_link}} {{transaction_date}} {{merchant_email}} {{merchant_contact}} {{merchant_name}}*/}</b>
                                                            </p>

                                                            <h6 className="sh">Message Subject</h6>
                                                            <h5 className="mh">Kind Reminder of Payment
                                                                for {/*{{transaction_id}}*/}</h5>

                                                            <div className="col-12 set-msg-bd no-pad">

                                                                <h6 className="sh">Message Body</h6>
                                                                <p className="mp mpt2">
                                                                    <b>Dear {/*{{customer_name}}*/}</b></p>
                                                                <p className="mp mpt2"><b>Please note that the Payment
                                                                    Link for Transaction ID {/*{{transaction_id}}*/} was
                                                                    sent
                                                                    to you on {/*{{transaction_date}};*/} however, the
                                                                    payment
                                                                    was not yet recorded on the system. Please be kind
                                                                    to settle this amount at your earliest convenience
                                                                    via {/*{{payment_link}}*/}</b></p>
                                                                <p className="mp mpt2"><b>Should you have any questions
                                                                    or concerns with the same, please feel free to get
                                                                    in touch with us via email
                                                                    on {/*{{merchant_email}}*/} or
                                                                    give us a call on {/*{{merchant_contact}}*/}.</b>
                                                                </p>
                                                                <p className="mp mpt2"><b>Yours
                                                                    Sincerely, {/*{{merchant_name}}*/}</b></p>


                                                            </div>

                                                        </div>

                                                        <div className="col-12">

                                                            <h4 className="sth mb">Turn On Automatic Reminders?</h4>
                                                            <p className="mp">Customize automated reminders to be sent
                                                                out to customers who haven't settled their invoices
                                                                after a set period of time. Please note that customers
                                                                will only receive email or SMS only if the details are
                                                                mentioned during the creation of the customer profile,
                                                                invoice or payment link.</p>

                                                            <div className="cbx model-11 mb-4">
                                                                <div className="checkbox">
                                                                    <input type="checkbox"/>
                                                                    <label></label>
                                                                </div>
                                                                <span className="txt">Enable Automatic Reminders</span>
                                                            </div>

                                                            <div className="row row_clr">
                                                                <div className="col-md-5 col-sm-12 set-fi no-pad">
                                                                    <h6 className="sh">Reminder Frequency</h6>
                                                                    <div className="form-group">
                                                                        <select name="" id="" className="form-control">
                                                                            <option value="">14 Days After Issued
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-7 col-sm-12 set-fi">
                                                                    <h6 className="sh mb-3">Channels</h6>
                                                                    <div className="row">
                                                                        <div
                                                                            className="col-lg-3 col-md-3 col-sm-12 p-0 cbox">
                                                                            <label className="cont set-cont">
                                                                                <input type="checkbox"/>
                                                                                <span className="checkmark"></span>
                                                                                Email
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            className="col-lg-3 col-md-4 col-sm-12 p-0 cbox">
                                                                            <label className="cont set-cont">
                                                                                <input type="checkbox"/>
                                                                                <span className="checkmark"></span>
                                                                                SMS
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <h5 className="mh mb-2">
                                                                <button type="button" className="icn tp"><i
                                                                    className="fas fa-plus" aria-hidden="true"></i> Add
                                                                    New Reminder
                                                                </button>
                                                            </h5>

                                                        </div>

                                                        <div className="col-12">
                                                            <h4 className="sth mb">Payment Refunds</h4>
                                                            <p className="mp">Edit the payment reminder message that is
                                                                sent out to your customers for unsettled invoices and
                                                                payment links here.</p>
                                                        </div>

                                                        <div className="col-12">
                                                            <h6 className="sh">Available Variables</h6>
                                                            <p className="mp mpt2">
                                                                <b></b>
                                                            </p>

                                                            <h6 className="sh">Message Subject</h6>
                                                            <h5 className="mh">Kind Reminder of Payment
                                                                for </h5>

                                                            <div className="col-12 set-msg-bd no-pad">

                                                                <h6 className="sh">Message Body</h6>
                                                                <p className="mp mpt2"><b>Dear </b></p>
                                                                <p className="mp mpt2"><b>This is to inform you that we
                                                                    have received your refund request for Transaction
                                                                    ID and have processed the refund
                                                                    to your account. You should receive the money in the
                                                                    next 5-7 business days.</b></p>
                                                                <p className="mp mpt2"><b>Please feel free to contact us
                                                                    at or call us
                                                                    on for further information.</b>
                                                                </p>
                                                                <p className="mp mpt2"><b>Regards, </b>
                                                                </p>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="three" id="v-pills-1">
                                                <div className="tab-pane fade show active" id="th" role="tabpanel"
                                                     aria-labelledby="th-tab">
                                                    <div className="row row_clr">
                                                        <div className="col-12">
                                                            <h3 className="tdh">Terms & Conditions</h3>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="rg-container">
                                                                <table className="transactions-table" summary="Hed">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="">Terms & Conditions</th>
                                                                        <th className=""></th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>


                                                                    {terms_conditions_lists}
                                                                    </tbody>
                                                                </table>
                                                                <ReactPaginate
                                                                    previousLabel={"PREV"}
                                                                    nextLabel={"NEXT"}
                                                                    breakLabel={"..."}
                                                                    breakClassName={"break-me"}
                                                                    pageCount={this.state.pageCountTermsConditions}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={this.handlePageClickTerms}
                                                                    containerClassName={"pagination"}
                                                                    subContainerClassName={"pages pagination"}
                                                                    activeClassName={"active"}/>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 set-fi">
                                                            <h6 className="sh">Add Terms & Conditions</h6>
                                                            <div className="form-group">
                                                                {/*<textarea name="terms_conditions" id=""
                                                                          className="form-control tfc"
                                                                          cols="30" rows="3" onChange={this.onChanges}
                                                                          value={this.state.terms_conditions}></textarea>*/}
                                                                <SunEditor onChange={this.handleChangeText} setContents={this.state.terms_conditions} />

                                                            </div>
                                                            {this.state.save_status == true ? (
                                                                    <button type="button" className="nxt-btn"
                                                                            onClick={this.saveTermsConditions}>Save Terms &
                                                                        Conditions &nbsp;&nbsp;<i className="fas fa-save"
                                                                                                  aria-hidden="true"></i>
                                                                    </button>) :
                                                                ('')
                                                            }
                                                            {this.state.update_status == true ? (
                                                                    <React.Fragment>
                                                                        <button type="button" className="nxt-btn"
                                                                                onClick={this.editTermsConditions}>Edit
                                                                            Terms &
                                                                            Conditions &nbsp;&nbsp;<i
                                                                                className="fas fa-save"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button type="button" className="nxt-btn mr-3"
                                                                                onClick={this.cancelEditTerms}>Cancel
                                                                            Editing &nbsp;&nbsp;<i
                                                                                className="fas fa-save"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                    </React.Fragment>
                                                                ) :
                                                                ('')
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>

                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>

        );
    }

}


