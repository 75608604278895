import React, {useContext} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {myConfig} from "../config";
import axios from "axios";
import IdleTimer from "react-idle-timer";
import NumberFormat from "react-number-format";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import LinearDeterminate from "./ProgressBar";
import {GetWebxLaravel, PostCompnentWebx} from "./PostCompnent";
import LogoutMessage from "./LogoutMessage";
import {decode} from "html-entities";
import Switch from "react-switch";

export class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lkr_transaction_total: 0,
            total_due: 0,
            total_due_usd: 0,
            doing_business_name: "",
            logo_file: sessionStorage.getItem("logo_image"),
            //idle timer
            timeout: 1000 * 600 * 1,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            navigation: [],
        };
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.loadTotalDue = this.loadTotalDue.bind(this);
        this.getUser = this.getUser.bind(this);
        this.showUserMenu = this.showUserMenu.bind(this);
        this.closePop = this.closePop.bind(this);

        this.idleTimer = null;
        this.onAction = this.onAction.bind(this);
        this.onActive = this.onActive.bind(this);
        this.onIdle = this.onIdle.bind(this);
        this.getCustomerData = this.getCustomerData.bind(this);
        this.getImage = this.getImage.bind(this);
        this.renderNavigation = this.renderNavigation.bind(this);
        this.clickDiv = this.clickDiv.bind(this);
    }

    renderNavigation() {
        //let admin_status="false";
        let admin_status = sessionStorage.getItem("admin_status");
        let permissions = sessionStorage.getItem("permissions");
        let all_permissions = JSON.parse(permissions);
        let comp = new Array();
        let trans1 = [];

        if (
            sessionStorage.getItem("merchant_view") == "IPG" ||
            sessionStorage.getItem("merchant_view") == null
        ) {
            if (sessionStorage.getItem("qr_enable") == 2) {
                trans1 = (
                    <>
                        <li
                            className={
                                this.props.pathname === "active-dashboard" ? "active" : ""
                            }
                        >
                            <Link to="../active-dashboard" replace>
                                <img
                                    src={require("../images/nav-icon-1-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-1-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Dashboard
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={this.props.pathname === "transactions" ? "active" : ""}
                        >
                            <Link to="../transactions" replace>
                                <img
                                    src={require("../images/nav-icon-2-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-2-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Transactions
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={this.props.pathname === "settlements" ? "active" : ""}
                        >
                            <Link to="../settlements">
                                <img
                                    src={require("../images/nav-icon-3-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-3-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Settlements
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={
                                this.props.pathname === "reports-metrics" ? "active" : ""
                            }
                        >
                            <Link to="../reports-metrics">
                                <img
                                    src={require("../images/nav-icon-6-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-6-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Reports &amp; Metrics
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={
                                this.props.pathname === "settings-general" ? "active" : ""
                            }
                        >
                            <Link to="../settings-general">
                                <img
                                    src={require("../images/nav-icon-8-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-8-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Settings
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                    </>
                );
            } else if (sessionStorage.getItem("qr_enable") == 1) {
                trans1 = (
                    <>
                        <li
                            className={
                                this.props.pathname === "active-dashboard" ? "active" : ""
                            }
                        >
                            <Link to="../active-dashboard" replace>
                                <img
                                    src={require("../images/nav-icon-1-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-1-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Dashboard
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={this.props.pathname === "transactions" ? "active" : ""}
                        >
                            <Link to="../transactions" replace>
                                <img
                                    src={require("../images/nav-icon-2-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-2-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Transactions
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={this.props.pathname === "settlements" ? "active" : ""}
                        >
                            <Link to="../settlements">
                                <img
                                    src={require("../images/nav-icon-3-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-3-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Settlements
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={this.props.pathname === "all-invoices" ? "active" : ""}
                        >
                            <Link to="../all-invoices">
                                <img
                                    src={require("../images/nav-icon-4-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-4-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Invoices
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={
                                this.props.pathname === "payment-links" ? "active" : ""
                            }
                        >
                            <Link
                                to={sessionStorage.getItem("school") == 1 ? "../payment-links-school" : "../payment-links"}>
                                <img
                                    src={require("../images/nav-icon-5-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-5-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Payment Links
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>


                        <li
                            className={
                                this.props.pathname === "reports-metrics" ? "active" : ""
                            }
                        >
                            <Link to="../reports-metrics">
                                <img
                                    src={require("../images/nav-icon-6-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-6-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Reports &amp; Metrics
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li className={this.props.pathname === "customers" ? "active" : ""}>
                            <Link to="../customer">
                                <img
                                    src={require("../images/nav-icon-7-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-7-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Customers
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={
                                this.props.pathname === "settings-general" ? "active" : ""
                            }
                        >
                            <Link to="../settings-general">
                                <img
                                    src={require("../images/nav-icon-8-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-8-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Settings
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                    </>
                );
            } else {
                trans1 = (
                    <>
                        <li
                            className={
                                this.props.pathname === "active-dashboard" ? "active" : ""
                            }
                        >
                            <Link to="../active-dashboard" replace>
                                <img
                                    src={require("../images/nav-icon-1-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-1-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Dashboard
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={this.props.pathname === "transactions" ? "active" : ""}
                        >
                            <Link to="../transactions" replace>
                                <img
                                    src={require("../images/nav-icon-2-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-2-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Transactions
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={this.props.pathname === "settlements" ? "active" : ""}
                        >
                            <Link to="../settlements">
                                <img
                                    src={require("../images/nav-icon-3-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-3-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Settlements
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={this.props.pathname === "all-invoices" ? "active" : ""}
                        >
                            <Link to="../all-invoices">
                                <img
                                    src={require("../images/nav-icon-4-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-4-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Invoices
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={
                                this.props.pathname === "payment-links" ? "active" : ""
                            }
                        >
                            <Link
                                to={sessionStorage.getItem("school") == 1 ? "../payment-links-school" : "../payment-links"}>
                                <img
                                    src={require("../images/nav-icon-5-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-5-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Payment Links
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>

                        <li
                            className={
                                this.props.pathname === "reports-metrics" ? "active" : ""
                            }
                        >
                            <Link to="../reports-metrics">
                                <img
                                    src={require("../images/nav-icon-6-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-6-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Reports &amp; Metrics
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li className={this.props.pathname === "customers" ? "active" : ""}>
                            <Link to="../customer">
                                <img
                                    src={require("../images/nav-icon-7-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-7-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Customers
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                        <li
                            className={
                                this.props.pathname === "settings-general" ? "active" : ""
                            }
                        >
                            <Link to="../settings-general">
                                <img
                                    src={require("../images/nav-icon-8-greay.svg")}
                                    alt=""
                                    className="ni gi"
                                />
                                <img
                                    src={require("../images/nav-icon-8-white.svg")}
                                    alt=""
                                    className="ni wi"
                                />
                                Settings
                                <span className="na">
                  <i className="fas fa-chevron-right"></i>
                </span>
                            </Link>
                        </li>
                    </>
                );
            }
        } else {
            trans1 = (
                <>
                    <li
                        className={this.props.pathname === "bpsp-dashboard" ? "active" : ""}
                    >
                        <Link to="../bpsp-dashboard">
                            <img
                                src={require("../images/nav-icon-1-greay.svg")}
                                alt=""
                                className="ni gi"
                            />
                            <img
                                src={require("../images/nav-icon-1-white.svg")}
                                alt=""
                                className="ni wi"
                            />
                            Dashboard
                            <span className="na">
                <i className="fas fa-chevron-right"></i>
              </span>
                        </Link>
                    </li>
                    <li className={this.props.pathname === "suppliers" ? "active" : ""}>
                        <Link to="../suppliers">
                            <img
                                src={require("../images/nav-icon-1-greay.svg")}
                                alt=""
                                className="ni gi"
                            />
                            <img
                                src={require("../images/nav-icon-1-white.svg")}
                                alt=""
                                className="ni wi"
                            />
                            Suppliers
                            <span className="na">
                <i className="fas fa-chevron-right"></i>
              </span>
                        </Link>
                    </li>
                    <li
                        className={
                            this.props.pathname === "bpsp-transaction-list" ? "active" : ""
                        }
                    >
                        <Link to="../bpsp-transaction-list">
                            <img
                                src={require("../images/nav-icon-1-greay.svg")}
                                alt=""
                                className="ni gi"
                            />
                            <img
                                src={require("../images/nav-icon-1-white.svg")}
                                alt=""
                                className="ni wi"
                            />
                            Transactions
                            <span className="na">
                <i className="fas fa-chevron-right"></i>
              </span>
                        </Link>
                    </li>

                </>
            );
        }

        if (all_permissions && all_permissions.length) {
            if (
                sessionStorage.getItem("merchant_view") == "IPG" ||
                sessionStorage.getItem("merchant_view") == null
            ) {
                comp.push(
                    <li
                        className={this.props.pathname === "active-dashboard" ? "active" : ""}
                    >
                        <Link to="../active-dashboard">
                            <img
                                src={require("../images/nav-icon-1-greay.svg")}
                                alt=""
                                className="ni gi"
                            />
                            <img
                                src={require("../images/nav-icon-1-white.svg")}
                                alt=""
                                className="ni wi"
                            />
                            Dashboard
                            <span className="na">
              <i className="fas fa-chevron-right"></i>
            </span>
                        </Link>
                    </li>
                );
            }
            all_permissions.map((list, key) => {
                (() => {
                    if (
                        sessionStorage.getItem("merchant_view") == "IPG" ||
                        sessionStorage.getItem("merchant_view") == null
                    ) {
                        if (list.component_name == "Transactions") {
                            comp.push(
                                <li
                                    className={
                                        this.props.pathname === "transactions" ? "active" : ""
                                    }
                                >
                                    <Link to="../transactions">
                                        <img
                                            src={require("../images/nav-icon-2-greay.svg")}
                                            alt=""
                                            className="ni gi"
                                        />
                                        <img
                                            src={require("../images/nav-icon-2-white.svg")}
                                            alt=""
                                            className="ni wi"
                                        />
                                        Transactions
                                        <span className="na">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                                    </Link>
                                </li>
                            );
                        }
                        if (list.component_name == "Settlements") {
                            comp.push(
                                <>
                                    <li
                                        className={
                                            this.props.pathname === "settlements" ? "active" : ""
                                        }
                                    >
                                        <Link to="../settlements">
                                            <img
                                                src={require("../images/nav-icon-3-greay.svg")}
                                                alt=""
                                                className="ni gi"
                                            />
                                            <img
                                                src={require("../images/nav-icon-3-white.svg")}
                                                alt=""
                                                className="ni wi"
                                            />
                                            Settlements
                                            <span className="na">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                                        </Link>
                                    </li>
                                </>
                            );
                        }
                        if (list.component_name == "Invoices") {
                            comp.push(
                                <li
                                    className={
                                        this.props.pathname === "all-invoices" ? "active" : ""
                                    }
                                >
                                    <Link to="../all-invoices">
                                        <img
                                            src={require("../images/nav-icon-4-greay.svg")}
                                            alt=""
                                            className="ni gi"
                                        />
                                        <img
                                            src={require("../images/nav-icon-4-white.svg")}
                                            alt=""
                                            className="ni wi"
                                        />
                                        Invoices
                                        <span className="na">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                                    </Link>
                                </li>
                            );
                        }
                        if (list.component_name == "Payment Links") {
                            comp.push(
                                <li
                                    className={
                                        this.props.pathname === "payment-links" ? "active" : ""
                                    }
                                >
                                    <Link
                                        to={sessionStorage.getItem("school") == 1 ? "../payment-links-school" : "../payment-links"}>
                                        <img
                                            src={require("../images/nav-icon-5-greay.svg")}
                                            alt=""
                                            className="ni gi"
                                        />
                                        <img
                                            src={require("../images/nav-icon-5-white.svg")}
                                            alt=""
                                            className="ni wi"
                                        />
                                        Payment Links
                                        <span className="na">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                                    </Link>
                                </li>
                            );
                        }
                        if (list.component_name == "Reports and Metrics") {
                            comp.push(
                                <li
                                    className={
                                        this.props.pathname === "reports-metrics" ? "active" : ""
                                    }
                                >
                                    <Link to="../reports-metrics">
                                        <img
                                            src={require("../images/nav-icon-6-greay.svg")}
                                            alt=""
                                            className="ni gi"
                                        />
                                        <img
                                            src={require("../images/nav-icon-6-white.svg")}
                                            alt=""
                                            className="ni wi"
                                        />
                                        Reports &amp; Metrics
                                        <span className="na">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                                    </Link>
                                </li>
                            );
                        }
                        if (list.component_name == "Customers") {
                            comp.push(
                                <li
                                    className={this.props.pathname === "customers" ? "active" : ""}
                                >
                                    <Link to="../customer">
                                        <img
                                            src={require("../images/nav-icon-7-greay.svg")}
                                            alt=""
                                            className="ni gi"
                                        />
                                        <img
                                            src={require("../images/nav-icon-7-white.svg")}
                                            alt=""
                                            className="ni wi"
                                        />
                                        Customers
                                        <span className="na">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                                    </Link>
                                </li>
                            );
                        }
                        if (list.component_name == "Settings General") {
                            comp.push(
                                <li
                                    className={
                                        this.props.pathname === "settings-general" ? "active" : ""
                                    }
                                >
                                    <Link to="../settings-general">
                                        <img
                                            src={require("../images/nav-icon-8-greay.svg")}
                                            alt=""
                                            className="ni gi"
                                        />
                                        <img
                                            src={require("../images/nav-icon-8-white.svg")}
                                            alt=""
                                            className="ni wi"
                                        />
                                        Settings
                                        <span className="na">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                                    </Link>
                                </li>
                            );
                        }
                    }else if (list.component_name == "BPSP Maker" || list.component_name == "Bpsp Checker") {
                            comp.push(
                                <>
                                    <li
                                        className={this.props.pathname === "bpsp-dashboard" ? "active" : ""}
                                    >
                                        <Link to="../bpsp-dashboard">
                                            <img
                                                src={require("../images/nav-icon-1-greay.svg")}
                                                alt=""
                                                className="ni gi"
                                            />
                                            <img
                                                src={require("../images/nav-icon-1-white.svg")}
                                                alt=""
                                                className="ni wi"
                                            />
                                            Dashboard
                                            <span className="na">
                <i className="fas fa-chevron-right"></i>
              </span>
                                        </Link>
                                    </li>
                                    <li className={this.props.pathname === "suppliers" ? "active" : ""}>
                                        <Link to="../suppliers">
                                            <img
                                                src={require("../images/nav-icon-1-greay.svg")}
                                                alt=""
                                                className="ni gi"
                                            />
                                            <img
                                                src={require("../images/nav-icon-1-white.svg")}
                                                alt=""
                                                className="ni wi"
                                            />
                                            Suppliers
                                            <span className="na">
                <i className="fas fa-chevron-right"></i>
              </span>
                                        </Link>
                                    </li>
                                    <li
                                        className={
                                            this.props.pathname === "bpsp-transaction-list" ? "active" : ""
                                        }
                                    >
                                        <Link to="../bpsp-transaction-list">
                                            <img
                                                src={require("../images/nav-icon-1-greay.svg")}
                                                alt=""
                                                className="ni gi"
                                            />
                                            <img
                                                src={require("../images/nav-icon-1-white.svg")}
                                                alt=""
                                                className="ni wi"
                                            />
                                            Transactions
                                            <span className="na">
                <i className="fas fa-chevron-right"></i>
              </span>
                                        </Link>
                                    </li>

                                </>
                            );
                        } else {
                        return <div>catch all</div>;
                    }
                })();
            });
        }

        let new_permissions = [];
        if (admin_status == "false") {
            new_permissions = comp;
        } else {
            new_permissions = trans1;
        }

        this.setState({
            navigation: new_permissions,
        });
    }

    getImage(laravel_token) {
        this.handleToggleProgress();

        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: sessionStorage.getItem("laravel_token"),
            },
        };

        return axios
            .get(
                BaseUrl +
                "merchants/get_image/" +
                sessionStorage.getItem("merchant_id") +
                "|" +
                sessionStorage.getItem("encrypted_merchant_id"),
                lar_config
            )
            .then((response) => {
                this.handleCloseProgress();
                this.setState({
                    logo_file:
                        response.data !== null
                            ? response.data
                            : sessionStorage.getItem("logo_image"),
                });
            })
            .catch((error) => {
                /*         if (error.response){
                                     if (error.response.status==401){
                                         sessionStorage.removeItem('userToken');
                                         localStorage.removeItem('userToken');
                                         sessionStorage.removeItem('laravel_token');
                                         localStorage.removeItem('laravel_token');
                                         localStorage.removeItem('merchant_id');
                                         sessionStorage.removeItem('merchant_id');
                                         window.location.reload();
                                     }
                                 }*/
            });
    }

    getCustomerData(userToken) {
        let BaseUrl = myConfig.apiUrl;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: "Bearer" + " " + userToken,
            },
        };
        let formData = new FormData();
        PostCompnentWebx("getStoreData", formData).then((response) => {
            sessionStorage.setItem(
                "doing_business_name",
                response.data[0].doing_business_name
            );
            sessionStorage.setItem(
                "merchant_number",
                response.data[0].merchant_number
            );
            sessionStorage.setItem("bpsp_status", response.data[0].bpsp_status);
            sessionStorage.setItem("bpsp_mdr_rate", response.data[0].bpsp_mdr_rate);
            sessionStorage.setItem(
                "qr_enable",
                response.data[0].allowed_transaction_type
            );
            this.setState({
                doing_business_name: decode(response.data[0].doing_business_name),
            });
        });
    }

    onAction(e) {
        this.setState({isTimedOut: false});
    }

    onActive(e) {
        this.setState({isTimedOut: false});
    }

    onIdle(e) {
        const isTimedOut = this.state.isTimedOut;
        if (isTimedOut) {
            sessionStorage.removeItem('userToken');
            localStorage.removeItem('userToken');
            sessionStorage.removeItem('laravel_token');
            localStorage.removeItem('laravel_token');
            localStorage.removeItem('merchant_id');
            sessionStorage.removeItem('merchant_id');
            sessionStorage.removeItem('doing_business_name');
            localStorage.removeItem("collapsed");
            localStorage.removeItem("doing_business_name");
            sessionStorage.removeItem("doing_business_name");
            sessionStorage.removeItem("encrypted_merchant_id");
            sessionStorage.removeItem("logo_image");
            sessionStorage.removeItem("merchant_number");
            sessionStorage.removeItem("user_name");
            sessionStorage.removeItem("permissions");
            sessionStorage.removeItem("admin_status");
            sessionStorage.removeItem('bpsp_status');
            sessionStorage.removeItem("merchant_view")

            sessionStorage.removeItem("password_change")
            sessionStorage.removeItem("new_password")
            sessionStorage.removeItem("bpsp_mdr_rate")
            sessionStorage.removeItem("total_due_usd")
            sessionStorage.removeItem("total_due_lkr")
            sessionStorage.removeItem("user_email")
            sessionStorage.removeItem("qr_enable")

            sessionStorage.removeItem("admin_status");

            sessionStorage.removeItem("sms_enabled");
            sessionStorage.removeItem("emi_enabled");
            sessionStorage.removeItem("school");
            sessionStorage.removeItem("pos_merchant");


            window.location.reload();
        } else {
            this.setState({showModal: true});
            this.idleTimer.reset();
            this.setState({isTimedOut: true});
        }
    }

    closePop() {
        var dotButton = document.getElementById("dot-button");
        var userMenu = document.getElementById("user-menu");
        userMenu.classList.remove("active");
        dotButton.classList.remove("active");
    }

    showUserMenu() {
        var dotButton = document.getElementById("dot-button");
        var userMenu = document.getElementById("user-menu");
        if (userMenu.classList.contains("active")) {
            userMenu.classList.remove("active");
            dotButton.classList.remove("active");
        } else {
            userMenu.classList.add("active");
            dotButton.classList.add("active");
        }
    }

    getUser() {
        let formData = new FormData();
        this.setState({
            doing_business_name: sessionStorage.getItem("doing_business_name"),
        });
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    loadTotalDue() {
        this.handleToggleProgress();

        let BaseUrl = myConfig.apiUrl;
        let formData = new FormData(); //formdata objec
        PostCompnentWebx("getMerchantDue", formData).then((response) => {
            this.handleCloseProgress();
            if (response.data.lkr_total !== undefined) {
                this.setState({total_due: response.data.lkr_total});
            }
            if (response.data.usd_total !== undefined) {
                this.setState({total_due_usd: response.data.usd_total});
            }
        });
    }

    clickDiv() {
    }

    componentWillMount() {
        if (
            sessionStorage.getItem("userToken") ||
            localStorage.getItem("userToken")
        ) {
            this.getUser();

            setTimeout(this.renderNavigation, 1000);

            //
            var userToken =
                sessionStorage.getItem("userToken") ||
                localStorage.getItem("userToken");
            this.getImage(sessionStorage.getItem("laravel_token"));
            this.setState({userToken: userToken});
            this.loadTotalDue();
            if (sessionStorage.getItem("doing_business_name") == null) {
                this.getCustomerData(sessionStorage.getItem("userToken"));
            } else if (
                typeof sessionStorage.getItem("doing_business_name") == "undefined"
            ) {
                this.getCustomerData(sessionStorage.getItem("userToken"));
            }
        } else {
            this.setState({redirect: true});
        }
        if (
            sessionStorage.getItem("laravel_token") ||
            localStorage.getItem("laravel_token")
        ) {
            this.getImage(sessionStorage.getItem("laravel_token"));
        }
    }

    render() {
        let lkr_total = this.state.total_due;
        let usd_total = this.state.total_due_usd;

        let is_admin = sessionStorage.getItem("admin_status");
        let user_name = sessionStorage.getItem("user_name");
        let name;
        if (is_admin == "false") {
            name = user_name;
        } else {
            name = decode(this.state.doing_business_name);
        }

        return (
            <>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout}
                />
                <div id="side-navigation" className="col">
                    <div id="user-menu">
                        <div className="pm-in">
                            <ul>
                                {/*  <li data-toggle="modal" data-target="#create-invoice-modal" className="l-item">
                                <div className="icn"><i className="fas fa-sign-out-alt"></i></div>
                                <p className="tp">Log Out of System </p>
                                <p className="bp">Sign Out of this Session</p>
                            </li>*/}
                                {is_admin == "true" ? (
                                    <li className="l-item">
                                        <Link to="..//settings-general" onClick={this.closePop}>
                                            <div className="icn">
                                                <i className="fas fa-user-cog"></i>
                                            </div>
                                            <p className="tp">View & Edit Profile </p>
                                            <p className="bp">
                                                View your User Profile & Make Changes
                                            </p>
                                        </Link>
                                    </li>
                                ) : (
                                    ""
                                )}
                                <LogoutMessage/>
                            </ul>
                        </div>
                    </div>

                    <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>

                    <div className="row row_clr sn-row">
                        <div className="col-12 logo-outer">
                            <img
                                src={require("../images/webxpay-dashboard-logo.svg")}
                                style={{maxWidth: "320px"}}
                                alt="logo"
                                className="logo"
                            />
                        </div>
                        {sessionStorage.getItem("merchant_view") == "IPG" ||
                        sessionStorage.getItem("merchant_view") == null ? (
                            <div className="col-12 act-acc">
                                <h3>
                                    Total Due Amount
                                    {/* <img src={require('../images/arrow-black.svg')}  alt="arrow" className="arrow-r" />*/}
                                    {/* <span style={{fontSize:"16px"}}>   <i className="fas fa-sync-alt" aria-hidden="true"></i> </span>*/}
                                </h3>

                                <h2 className="t-amt">
                                    <NumberFormat
                                        value={(Math.round(lkr_total * 100) / 100).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"LKR "}
                                    />
                                </h2>
                                {usd_total !== 0 ? (
                                    <h2 className="t-amt">
                                        <NumberFormat
                                            value={(Math.round(usd_total * 100) / 100).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"USD "}
                                        />
                                    </h2>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            <div className="col-12 act-acc"></div>
                        )}
                        {/* <div className="col-12 act-acc">
                            <h3>Activate Your Account <img src={require('../images/arrow-black.svg')} alt="arrow" className="arrow-r" />
                            </h3>
                            <p className="ns">Next Step : <span>Choose Subscription Plan</span></p>
                            <div className="row row_clr line-outer">
                                <div className="col-12 line-comp">
                                    <div className="gl"></div>
                                </div>
                                <span>15% Completed</span>
                                <span>18 Days Left</span>
                            </div>
                        </div>*/}

                        <div className="col-12 nav-list-outer">
                            <ul className="nav-list">
                                {this.state.navigation}
                                {/*  <li className={this.props.pathname === 'active-dashboard' ? 'active' : ''}>
                                    <Link to="../active-dashboard">
                                        <img src={require('../images/nav-icon-1-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../images/nav-icon-1-white.svg')} alt="" className="ni wi" />
                                        Dashboard
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>

                                            <li className={this.props.pathname === 'transactions' ? 'active' : ''}>
                                                <Link to='transactions'>
                                                    <img src={require('../images/nav-icon-2-greay.svg')} alt="" className="ni gi" />
                                                    <img src={require('../images/nav-icon-2-white.svg')} alt="" className="ni wi" />
                                                    Transactions
                                                    <span className="na"><i className="fas fa-chevron-right"></i></span>
                                                </Link>
                                            </li>


                                <li className={this.props.pathname === 'settlements' ? 'active' : ''}>
                                    <Link to='settlements'>
                                        <img src={require('../images/nav-icon-3-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../images/nav-icon-3-white.svg')} alt="" className="ni wi" />
                                        Settlements
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'all-invoices' ? 'active' : ''}>
                                    <Link to='all-invoices'>
                                        <img src={require('../images/nav-icon-4-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../images/nav-icon-4-white.svg')} alt="" className="ni wi" />
                                        Invoices
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'payment-links' ? 'active' : ''}>
                                    <Link to="../payment-links">
                                        <img src={require('../images/nav-icon-5-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../images/nav-icon-5-white.svg')} alt="" className="ni wi" />
                                        Payment Links
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'reports-metrics' ? 'active' : ''}>
                                    <Link to="../reports-metrics">
                                        <img src={require('../images/nav-icon-6-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../images/nav-icon-6-white.svg')} alt="" className="ni wi" />
                                        Reports &amp; Metrics
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'customers' ? 'active' : ''}>
                                    <Link to="../customer">
                                        <img src={require('../images/nav-icon-7-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../images/nav-icon-7-white.svg')} alt="" className="ni wi" />
                                        Customers
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'settings-general' ? 'active' : ''}>
                                    <Link to="../settings-general">
                                        <img src={require('../images/nav-icon-8-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../images/nav-icon-8-white.svg')} alt="" className="ni wi" />
                                        Settings
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>*/}
                            </ul>
                        </div>
                        <div className="col-12 nav-user">
                            <div className="row row_clr nu-in">
                                <a>
                                    <img
                                        src={sessionStorage.getItem("logo_image")}
                                        alt="user"
                                        className="user"
                                        style={{width: 30}}
                                    />
                                    {name}
                                    <button
                                        type="button"
                                        onClick={this.showUserMenu}
                                        style={{
                                            background: "none",
                                            border: "none",
                                            outline: "none",
                                        }}
                                    >
                                        <img
                                            src={require("../images/dots.svg")}
                                            alt=""
                                            id="dot-button"
                                            className="dots"
                                        />
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
