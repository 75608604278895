export const myConfig = {
 // apiUrl: "https://webxpay.com/index.php?route=api/mobile_api/",
 // bpspApi: "https://webxpay.com/index.php?route=api/bpsp_api/",

  // Staging
 apiUrl: "https://stagingxpay.info/index.php?route=api/mobile_api/",
 bpspApi: "https://stagingxpay.info/index.php?route=api/bpsp_api/",

  //Live
 // laravelUrl:'https://api.webxapi.online/api/',
 // laravelWeb:'https://api.webxapi.online/',

  //Live Webx New
  //laravelUrl: 'https://merchantapi.webxpay.com/api/',
  //laravelWeb: 'https://merchantapi.webxpay.com/',

  //live
//  laravelUrl: "https://merchantapi.webxpay.com/api/",
//  laravelWeb: "https://merchantapi.webxpay.com/",

  //Redirection local
 //redirectWeb: 'http://laravel-merchant-dashboard.test/',
// redirectUrl: 'http://laravel-merchant-dashboard.test/api/',

  //Local
 //laravelWeb:'http://laravel-merchant-dashboard.test/',
 //laravelUrl:'http://laravel-merchant-dashboard.test/api/',


    //Redirection local
 //  redirectWeb: 'http://webx.local/',
  //  redirectUrl: 'http://webx.local/api/',

    //Local
  // laravelWeb:'http://webx.local/',
 //  laravelUrl: 'http://webx.local/api/',

 // Redirection
  //  redirectWeb: "https://signup.webxpay.com/",
  //  redirectUrl: "https://signup.webxpay.com/api/",

  //Staging
   laravelUrl: 'http://staginglaravel.webxapi.online/api/',
   laravelWeb: 'http://staginglaravel.webxapi.online/',

  //Staging Redirection
   redirectWeb: 'http://staginglaravel.webxapi.online/',
   redirectUrl: 'http://staginglaravel.webxapi.online/api/',

    //Token Staging Url
    tokenStaging:'https://commtoken.webxpay.com/api/'
};
