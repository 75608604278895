import React, { PureComponent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,Legend
} from 'recharts';
import {Navigation} from "./Navigation";
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "./PostCompnent";
import {Footer} from "./Footer";

export class InvoiceTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShowAddInvoice:false,
            setModalShowAddInvoice:false,
            modalShow:false,
            setModalShow:false,
            invoice_array:[],
            product_name:'',
            product_rate:'',
            product_quantity:'',
            template_name:'',
            template_data:[],
            template_data_edit:[],
            template_data_render:[],
            template_data_filters:[],
            invoice_data:[],
            items_data:[],
            modalShowAddInvoiceEdit:false,
            setModalShowAddInvoiceEdit:false,
            modalShowEdit:false,
            setModalShowEdit:false,
            template_name_edit:'',
            total_template_edit_total:0,
            product_name_edit:'',
            product_rate_edit:'',
            product_quantity_edit:'',
            template_id:'',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            template_name_search:'',
            amount_search:'',
            product_data:[],
            openprogress: false,
            setOpenProgress: false,
            modelButtonText:'Processing...',
            modalHeadText:'Processing...',
            productUpdateOrCreate:this.addProductNew,
            errors_product_add:[],
            errors_product_edit:[],
            err_temp_add:[],
            err_temp_edit:[],
            lkr_available:'',
            usd_available:''
        };

        this.selectChart = this.selectChart.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.viewModalInvoice = this.viewModalInvoice.bind(this);
        this.modalCloseInvoice = this.modalCloseInvoice.bind(this);
        this.addRemoveActive = this.addRemoveActive.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.viewModal = this.viewModal.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);

        this.saveTemplete = this.saveTemplete.bind(this);
        this.loadTemplateItems = this.loadTemplateItems.bind(this);
        this.fetchInvoiceData = this.fetchInvoiceData.bind(this);
        this.viewModalInvoiceEdit = this.viewModalInvoiceEdit.bind(this);
        this.modalCloseInvoiceEdit = this.modalCloseInvoiceEdit.bind(this);
        this.modalCloseEdit = this.modalCloseEdit.bind(this);
        this.viewModalEdit = this.viewModalEdit.bind(this);

        this.addProductNew = this.addProductNew.bind(this);
        this.editProductNew = this.editProductNew.bind(this);

        this.viewModalInvoiceEditor=this.viewModalInvoiceEditor.bind(this);
        this.modalCloseInvoiceEditor=this.modalCloseInvoiceEditor.bind(this);

        this.saveTemplateEdit=this.saveTemplateEdit.bind(this);
        this.updateProductEdit=this.updateProductEdit.bind(this);

        this.loadTable=this.loadTable.bind(this);
        this.handlePageClick=this.handlePageClick.bind(this);
        this.searchTemplate=this.searchTemplate.bind(this);
        this.clearSearch=this.clearSearch.bind(this);
        this.onChangesAuto=this.onChangesAuto.bind(this);
        this.onChangesAutoEdit=this.onChangesAutoEdit.bind(this);
        this.viewModalInvoiceSaveEditor=this.viewModalInvoiceSaveEditor.bind(this);

        //validate add template product
         this.handleValidationAddTemplateProduct=this.handleValidationAddTemplateProduct.bind(this);
        //validate edit template product
        this.handleValidationEditTemplateProduct=this.handleValidationEditTemplateProduct.bind(this);
        this.handleValidationInvoiceTempleteSave=this.handleValidationInvoiceTempleteSave.bind(this);
        this.handleValidationInvoiceTempleteEdit=this.handleValidationInvoiceTempleteEdit.bind(this);
    }

    handleValidationInvoiceTempleteSave(){
        let err_temp_add= {};
        let formIsValid = true;
        //Email


        if(this.state.template_name==""){
            formIsValid = false;
            err_temp_add["template_name"] = "Cannot be empty";
        }

        if(!this.state.invoice_array.length){
            formIsValid = false;
            err_temp_add["table_fill"] = "Please Add at least one product or service";
        }


        this.setState({err_temp_add: err_temp_add});
        return formIsValid;
    }
    handleValidationInvoiceTempleteEdit(){
        let err_temp_edit= {};
        let formIsValid = true;
        //Email

        if(this.state.template_name_edit==""){
            formIsValid = false;
            err_temp_edit["template_name_edit"] = "Cannot be empty";
        }


        if(!this.state.template_data_edit.length){
            formIsValid = false;
            err_temp_edit["table_fill_edit"] = "Cannot be empty";
        }

        this.setState({err_temp_edit: err_temp_edit});
        return formIsValid;
    }
    handleValidationAddTemplateProduct(){
        let errors_product_add= {};
        let formIsValid = true;
        //Email

        if(this.state.product_name==""){
            formIsValid = false;
            errors_product_add["product_name"] = "Cannot be empty";
        }

        if(this.state.product_rate==""){
            formIsValid = false;
            errors_product_add["product_rate"] = "Cannot be empty";
        }

        if(this.state.product_quantity==""){
            formIsValid = false;
            errors_product_add["product_quantity"] = "Cannot be empty";
        }

        this.setState({errors_product_add: errors_product_add});
        return formIsValid;
    }

    handleValidationEditTemplateProduct(){
        let errors_product_edit= {};
        let formIsValid = true;
        //Email

        if(this.state.product_name_edit==""){
            formIsValid = false;
            errors_product_edit["product_name_edit"] = "Cannot be empty";
        }

        if(this.state.product_rate_edit==""){
            formIsValid = false;
            errors_product_edit["product_rate_edit"] = "Cannot be empty";
        }

        if(this.state.product_quantity_edit==""){
            formIsValid = false;
            errors_product_edit["product_quantity_edit"] = "Cannot be empty";
        }

        this.setState({errors_product_edit: errors_product_edit});
        return formIsValid;

    }
    searchTemplate(){

        let template_name_search = this.state.template_name_search.toLowerCase();
        let template_amount_search = this.state.amount_search;


        let formData = new FormData();    //formdata object


        formData.append('template_name', template_name_search);   //append the values with key, value pair
        formData.append('template_amount',template_amount_search);

        this.handleToggleProgress();
        PostWebxLaravel( "template_detail/get_templates_with_filters",formData).then(response=>{
            this.handleCloseProgress();
            this.setState({
                template_data:response.data,
                template_data_filters:response.data,
            },()=>{
                this.loadTable();
            });
        });

    }

    clearSearch(){
        GetWebxLaravel("template_detail/get_template/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    template_data:response.data,
                    template_data_filters:response.data,
                },()=>{
                    this.loadTable();
                });
            }
        });
        this.setState({
            template_data_filters:this.state.template_data,
            startDate:'',
            endDate:'',
            template_name_search:'',
            amount_search:'',
        }, () => {
            this.loadTable()
        });
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable()
        });

    };
    saveTemplateEdit(){
        if (this.handleValidationInvoiceTempleteEdit()) {
            let invoice_array = this.state.template_data_edit;

            let itemsArr = [];
            invoice_array.forEach((item) => {
                itemsArr.push({
                    product_name: item.product_name,
                    quantity: item.quantity,
                    sub_total: item.sub_total,
                });
            })
            if (itemsArr.length > 0 && this.state.template_name_edit !== "") {

                let formData = new FormData();    //formdata object


                formData.append('template_items', JSON.stringify(itemsArr));   //append the values with key, value pair
                formData.append('template_name', this.state.template_name_edit);   //append the values with key, value pair
                formData.append('_method', 'put');
                this.handleToggleProgress();
                PostWebxLaravel("template_detail/update_template/" +sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+ this.state.template_id, formData).then(response => {
                    this.handleToggleProgress();

                    if (response && response.status == 200) {
                        this.handleCloseProgress();
                        this.setState({
                            modalShowEdit: false,
                            setModalShowEdit: false,
                        });

                        this.loadTemplateItems();
                    }
                });

            }
        }
    }
    fetchInvoiceData(){
        this.handleToggleProgress();
        GetWebxLaravel( "invoice_detail/get_invoice/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response) {
                this.handleCloseProgress();
                this.setState({
                    invoice_data: response.data,
                });
            }
        });
        this.handleToggleProgress();
        GetWebxLaravel("products/get_products/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response) {
                this.handleCloseProgress();
                this.setState({
                    items_data: response.data
                    //   single_invoice_details:response.data,
                });
            }
        });

        PostCompnentWebx("getStoreCurrency").then(response=>{
            let LKR=false;
            let USD=false;
            if (response.data.indexOf("LKR") > -1){
                LKR=true;
            }
            if (response.data.indexOf("USD") > -1){
                USD=true;
            }
            this.setState({
                lkr_available:LKR,
                usd_available:USD
            });
        });

    }

    saveTemplete(){
        if (this.handleValidationInvoiceTempleteSave()) {
            let invoice_array = this.state.invoice_array;
            let itemsArr = [];
            invoice_array.forEach((item) => {
                itemsArr.push({
                    product_name: item.product_name,
                    quantity: item.product_quantity,
                    sub_total: item.sub_total,
                });
            });
            if (this.state.invoice_array.length > 0 && this.state.template_name !== "") {

                let formData = new FormData();    //formdata object


                formData.append('template_items', JSON.stringify(itemsArr));   //append the values with key, value pair
                formData.append('template_name', this.state.template_name);   //append the values with key, value pair
                formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));   //append the values with key, value pair
                this.handleToggleProgress();
                PostWebxLaravel("template_detail/create_template", formData).then(response => {
                    if (response.status == 200) {
                        this.handleCloseProgress();
                        this.setState({
                            modalShow: false,
                            setModalShow: false,
                            invoice_array: [],
                        });
                        this.fetchInvoiceData();
                        this.loadTemplateItems();
                    }
                });
            }
        }
    }
    loadTemplateItems(){
        this.handleToggleProgress();
        GetWebxLaravel("template_detail/get_template/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    template_data:response.data,
                    template_data_filters:response.data,
                },()=>{
                    this.loadTable();
                });
            }
        });
    }


    addProductNew(){
        if(this.handleValidationAddTemplateProduct()){
            let product_name=this.state.product_name;
            let product_rate=this.state.product_rate;
            let product_quantity=this.state.product_quantity;
            this.setState(prevState => ({
                tax_payment:300,
                delivery_charge:500,
                invoice_array: [...prevState.invoice_array, {
                    product_name:product_name,
                    product_rate:product_rate,
                    product_quantity:product_quantity,
                    sub_total:parseFloat(product_quantity)*parseFloat(product_rate),
                }],
                product_name:'',
                product_rate:'',
                product_quantity:'',
                modalShowAddInvoice:false,
                setModalShowAddInvoice:false
            }));
            var modelD = document.getElementById("activation-modal");
            modelD.style.visibility = 'visible';
        }


    }
    updateProductNew(index){
        if(this.handleValidationAddTemplateProduct()) {
            const template_data_id = this.state.invoice_array.slice() //copy the array
            let product_name = this.state.product_name;
            let product_rate = this.state.product_rate;
            let product_quantity = this.state.product_quantity;
            template_data_id[index]['product_name'] = product_name; //execute the manipulations
            template_data_id[index]['quantity'] = product_quantity; //execute the manipulations
            template_data_id[index]['product_rate'] = product_rate; //execute the manipulations
            template_data_id[index]['sub_total'] = parseFloat(product_quantity) * parseFloat(product_rate);//execute the manipulations
            this.setState({invoice_array: template_data_id}) //set the new state


            this.setState(prevState => ({
                modalShowAddInvoice: false,
                setModalShowAddInvoice: false
            }))
            var modelD = document.getElementById("activation-modal");
            modelD.style.visibility = 'visible';
        }
    }
    updateProductEdit(index){
       if(this.handleValidationEditTemplateProduct()) {
           const template_data_id = this.state.template_data_edit.slice() //copy the array


           let product_name = this.state.product_name_edit;
           let product_rate = this.state.product_rate_edit;
           let product_quantity = this.state.product_quantity_edit;
           template_data_id[index]['product_name'] = product_name; //execute the manipulations
           template_data_id[index]['product_rate'] = product_rate; //execute the manipulations
           template_data_id[index]['quantity'] = product_quantity; //execute the manipulations
           template_data_id[index]['sub_total'] = parseFloat(product_quantity) * parseFloat(product_rate);//execute the manipulations
           this.setState({template_data_edit: template_data_id}) //set the new state

           this.setState(prevState => ({
               modalShowAddInvoiceEdit: false,
               setModalShowAddInvoiceEdit: false
           }))
           var modelD = document.getElementById("activation-modal");
           modelD.style.visibility = 'visible';
       }
    }
    editProductNew(){
        if(this.handleValidationEditTemplateProduct()) {
            let product_name = this.state.product_name_edit;
            let product_rate = this.state.product_rate_edit;
            let product_quantity = this.state.product_quantity_edit;



            this.setState(prevState => ({
                template_data_edit: [...prevState.template_data_edit, {
                    product_name: product_name,
                    quantity: product_quantity,
                    sub_total: parseFloat(product_quantity) * parseFloat(product_rate),
                }],
                modalShowAddInvoiceEdit: false,
                setModalShowAddInvoiceEdit: false
            }))
            var modelD = document.getElementById("activation-modal");
            modelD.style.visibility = 'visible';
        }
    }
    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    myFunc(e){

        var val = e;
        var re = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)$/g;
        var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
        if (re.test(val)) {
            //do something here

        } else {
            val = re1.exec(val);
            if (val) {
                e = val[0];
            } else {
               e = "";
            }
        }
        return e;
    }
    onChangeNumber(e){
        if (e.target.name == "product_rate" ||e.target.name == "product_rate_edit") {
            let new_val=this.myFunc(e.target.value)
            this.setState({  [e.target.name]: new_val})
        }else{
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {

                this.setState({[e.target.name]: e.target.value})
            }
        }
    }

    addRemoveActive(){
        var plusButton = document.getElementById("plus-button");
        var plusMenu = document.getElementById("plus-menu");
        if (plusMenu.classList.contains('active')) {
            plusMenu.classList.remove("active");
            plusButton.classList.remove("active");
        } else {
            plusMenu.classList.add("active");
            plusButton.classList.add("active");
        }
        var myElement = document.getElementsByClassName('l-item');
        for (var i = 0; i < myElement.length; i++) {
            myElement[i].onclick = function() {
                var plusButton = document.getElementById("plus-button");
                var plusMenu = document.getElementById("plus-menu");
                plusMenu.classList.remove("active");
                plusButton.classList.remove("active");
            }
        }
    }
    viewModalInvoice(){
        this.setState({
            modalShowAddInvoice:true,
            setModalShowAddInvoice:true,
            product_name:'',
            product_rate:'',
            product_quantity:'',
            modelButtonText:'Save',
            modalHeadText:'Add Products/Services',
            productUpdateOrCreate:this.addProductNew,
        }, () => {
            var modelD = document.getElementById("ani-modal");
            modelD.classList.add("ancd-mod");
        });
        if ( document.getElementById("activation-modal")){
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'hidden';
        }
    };
    modalCloseInvoice(){
        this.setState({
            modalShowAddInvoice:false,
            setModalShowAddInvoice:false
        })
        if ( document.getElementById("activation-modal")) {
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'visible';
        }
    }

    viewModalInvoiceEdit(){

        this.setState({
            product_name_edit:'',
            product_rate_edit:'',
            product_quantity_edit:'',
            clickEvent:this.editProductNew,
            modalShowAddInvoiceEdit:true,
            setModalShowAddInvoiceEdit:true,
            modelButtonText:'Save',
            modalHeadText:'Save Products/Services',

        }, () => {
            var modelD = document.getElementById("ani-modal");
            modelD.classList.add("ancd-mod");
        });
        if ( document.getElementById("activation-modal")){
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'hidden';
        }

    };
    modalCloseInvoiceEdit(){
        this.setState({
            modalShowAddInvoiceEdit:false,
            setModalShowAddInvoiceEdit:false
        })
        if ( document.getElementById("activation-modal")) {
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'visible';
        }
    }

    viewModalInvoiceEditor(index){
        const template_data_id = this.state.template_data_edit.slice() //copy the array
        this.setState({
            product_name_edit:template_data_id[index]['product_name'],
            product_quantity_edit: template_data_id[index]['quantity'],
            product_rate_edit: template_data_id[index]['sub_total']/template_data_id[index]['quantity'],
            modelButtonText:'Update',
            modalHeadText:'Edit Products/Services',

        }) //set the new state*/

        this.setState({
            clickEvent:()=>this.updateProductEdit(index),
            modalShowAddInvoiceEdit:true,
            setModalShowAddInvoiceEdit:true
        }, () => {
            var modelD = document.getElementById("ani-modal");
            modelD.classList.add("ancd-mod");
        });
        if ( document.getElementById("activation-modal")){
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'hidden';
        }

    };
    viewModalInvoiceSaveEditor(index){
        const template_data_id = this.state.invoice_array.slice() //copy the array
        this.setState({
            product_name:template_data_id[index]['product_name'],
            product_quantity: template_data_id[index]['product_quantity'],
            product_rate: template_data_id[index]['sub_total']/template_data_id[index]['product_quantity'],

        }) //set the new state*/

        this.setState({
            productUpdateOrCreate:()=>this.updateProductNew(index),
            modalShowAddInvoice:true,
            setModalShowAddInvoice:true,
            modelButtonText:'Update',
            modalHeadText:'Update Product/Service',
        }, () => {
            var modelD = document.getElementById("ani-modal");
            modelD.classList.add("ancd-mod");
        });
        if ( document.getElementById("activation-modal")){
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'hidden';
        }

    };
    modalCloseInvoiceEditor(){
        this.setState({
            modalShowAddInvoiceEdit:false,
            setModalShowAddInvoiceEdit:false
        })
        if ( document.getElementById("activation-modal")) {
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'visible';
        }
    }
    viewModal(){
        this.handleToggleProgress();
        GetWebxLaravel( "products/get_products/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    product_data:response.data,
                    modalShow:true,
                    setModalShow:true,
                    invoice_array:[],
                });
            }

        });


        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {
            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
        }


    };
    modalClose(){
        this.setState({
            modalShow:false,
            setModalShow:false
        })
    }
    onChangesAuto(e,value){
        this.setState({product_name:value});
    }
    onChangesAutoEdit(e,value){
        this.setState({product_name_edit:value});
    }

    viewModalEdit(id){
        this.handleToggleProgress();
        GetWebxLaravel("products/get_products/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    product_data:response.data
                    //   single_invoice_details:response.data,
                });
            }

        });
        this.handleToggleProgress();
        GetWebxLaravel( "template_detail/get_single_template/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+id).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({ template_id:id,template_data_edit: response.data.get_items,template_name_edit:response.data.template_name});
            }

        });

        this.setState({
            modalShowEdit:true,
            setModalShowEdit:true
        })

    };
    modalCloseEdit(){
        this.setState({
            modalShowEdit:false,
            setModalShowEdit:false
        })
    }

    selectChart(){
        let type_chart= document.getElementById("chart-selector").value;
        if(type_chart==="usd"){
            document.getElementById("chart_usd").style.display = "block";
            document.getElementById("chart_lkr").style.display = "none";
        }else{
            document.getElementById("chart_usd").style.display = "none";
            document.getElementById("chart_lkr").style.display = "block";
        }
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");

            this.setState({userToken: userToken});

            /*    this.loadPageData(userToken);
               */
            this.handleToggleProgress();
            this.loadTemplateItems();
            this.fetchInvoiceData();
        } else {
            this.setState({redirect: true});
        }
    }
    loadTable(){
        const list_data=this.state.template_data_filters;
        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);
        const trans=  list_data && list_data.length?(slice.map((list, key) =>
            <tr className='' key={key.toString()}>
                <td className='' data-title='#'>{key+1}</td>
                <td className='' data-title='Template Name'>
                    {list.template_detail.template_name}
                </td>
                {/* <td className='' data-title='Service/Product'>Product</td>*/}
                <td className='' data-title='Inclusive Items'>
                    {list.template_detail.get_items && list.template_detail.get_items.length?(
                        list.template_detail.get_items.map((lists, keys) =>
                        {
                            return (
                                <p key={keys.toString()}>{lists.product_name} X {lists.quantity} (<NumberFormat
                                    value={(Math.round(lists.sub_total * 100) / 100).toFixed(2)}
                                    displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>)</p>
                            )
                        })
                    ):('')
                    }
                </td>
                <td className='' data-title='Amount'>
                    <NumberFormat
                        value={(Math.round(list.subTotal  * 100) / 100).toFixed(2)}
                        displayType={'text'} thousandSeparator={true} prefix={' '}/>
                </td>
                <td className='' data-title=''>
                    <div className="dropdown">
                        <button className="btn dropdown-toggle dot-drop"
                                type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" onClick={()=>this.viewModalEdit(list.template_detail.id)}>
                            <i className="fas fa-pencil-alt"></i>
                        </button>
                        <div className="dropdown-menu table-drop it-3"
                             aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item"><i
                                className="fas fa-copy"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-pencil-alt"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-trash-alt"></i></button>
                        </div>
                    </div>
                </td>
            </tr>
        ) ):(
            <tr className=''>
                <td colSpan="5" className='' data-title='#'></td>
            </tr>

        )
        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            template_data_render:trans,
        });
    }


    render() {

        const invoice_array=this.state.invoice_array;
        const template_data=this.state.template_data;
        const total_templates=this.state.template_data.length;
        const items_data=this.state.items_data;
        const total_items=items_data.length;
        let total_amount = invoice_array.reduce(function(prev, current) {
            return prev + +current.sub_total
        }, 0);

        if (this.state.redirect) {
            return <Navigate to='/login'  />
        }
        const template_data_edit=this.state.template_data_edit;
        const invoice_data=this.state.invoice_data;
        const total_invoice_sub=invoice_data.reduce((total, currentValue) => total = total + parseFloat(currentValue.sub_total),0);
        let additional_crg=0;
        for (let i=0;i<invoice_data.length;i++){
            let additional_data=invoice_data[i].get_additional;
            for (let x=0;x<additional_data.length;x++){
                additional_crg+=additional_data[x].amount;
            }
        }
        let total_invoice_usd=0;
        let total_invoice_lkr=0;
        for (let i=0;i<invoice_data.length;i++){
            if (invoice_data[i].currency_id==2){
                total_invoice_usd+=parseFloat(invoice_data[i].total);
            }else{
                total_invoice_lkr+=parseFloat(invoice_data[i].total);
            }
        }

        let total_invoice=parseFloat(total_invoice_sub)+parseFloat(additional_crg);



        let total_template_edit_total=this.state.total_template_edit_total;
        if (template_data_edit){
            total_template_edit_total=template_data_edit.reduce((total, currentValue) => total = total + parseFloat(currentValue.sub_total),0);
        }
        return (

            <React.Fragment>

       {/*Invoice Edit =>Save*/}
                <Modal
                    show={this.state.modalShowEdit}
                    onHide={this.state.setModalShowEdit}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseEdit}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 lft cd-frm">
                                    <h3 className="tdh">Template Detail</h3>

                                    <div className="col-12 tdm-in">
                                        <h6>Template Name</h6>
                                        <div className="form-group">
                                            <input name="template_name_edit" value={this.state.template_name_edit} onChange={this.onChanges} type="text" className="form-control" placeholder="Template Name"  />
                                             <span className="error" style={{color: "red"}}>{this.state.err_temp_edit["template_name_edit"]}</span>

                                        </div>
                                        <h6 className="mt-4">Total Invoice Value</h6>

                                        {total_template_edit_total?(
                                            <h3 className="tdh mb-0">
                                                <NumberFormat
                                                    value={(Math.round(total_template_edit_total * 100) / 100).toFixed(2)}
                                                    displayType={'text'} thousandSeparator={true} prefix={' '}/></h3>
                                        ):('')}

                                    </div>


                                    <button type="button" className="ext-btn" onClick={this.saveTemplateEdit}>Save Template &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                    {/*    <button type="button" className="ext-btn ext-t2">Delete Invoice &nbsp;&nbsp;<i
                                     className="fas fa-trash-alt"></i></button>*/}
                                  {/*  <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                        Help with the System?</a>*/}
                                </div>
                                <div className="col-md-8 rht cd-il">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h3 className="tdh cup" data-toggle="modal" data-target="#create-template-modal"
                                                data-dismiss="modal" onClick={this.viewModalInvoiceEdit}>Add Template Items &nbsp;&nbsp;<i
                                                className="fas fa-folder-plus"></i></h3>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 details-box d-inv-box">
                                            <div className="col-12 dbx-in">

                                                <div className="row row_clr dit-in">
                                                    <div className="col-12 rht img-box p-0">

                                                        <div className='rg-container'>
                                                            <table className='transactions-table invoice-table' summary='Hed'>
                                                                <thead>
                                                                <tr>
                                                                    <th className=''>#</th>
                                                                    <th className=''>Title & Description</th>
                                                                    <th className=''>Rate/ITEM</th>
                                                                    <th className=''>qty</th>
                                                                    <th className=''>total</th>
                                                                    <th className=''></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {template_data_edit && template_data_edit.length?(
                                                                    template_data_edit.map((list, key) =>
                                                                    {
                                                                        return (
                                                                            <tr className=''>
                                                                                <td className='' data-title='#'>{key+1}</td>
                                                                                <td className='' data-title='Title & Description'>{list.product_name}
                                                                                </td>
                                                                                <td className='' data-title='Title & Description'>LKR {list.sub_total/list.quantity}
                                                                                </td>
                                                                                <td className='' data-title='Rate/ITEM'> {list.quantity}</td>
                                                                                <td className='' data-title='qty'><NumberFormat
                                                                                    value={(Math.round(list.sub_total * 100) / 100).toFixed(2)}
                                                                                    displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></td>
                                                                                <td className='' data-title='qty'><i
                                                                                    className="fas fa-pencil-alt" onClick={()=>(this.viewModalInvoiceEditor(key))}></i></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                ):(
                                                                    <tr className=''>
                                                                        <td colSpan="5" className='' data-title='#'></td>
                                                                    </tr>

                                                                )
                                                                }
                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''>Total </td>
                                                                    <td className='' data-title=''> <NumberFormat
                                                                        value={(Math.round(total_template_edit_total * 100) / 100).toFixed(2)}
                                                                        displayType={'text'} thousandSeparator={true} prefix={''}/></td>
                                                                    <td className='' data-title=''></td>
                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                         <span className="error" style={{color: "red"}}>{this.state.err_temp_edit["table_fill_edit"]}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

        {/*Invoice Add*/}
                <Modal
                    show={this.state.modalShow}
                    onHide={this.state.setModalShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 lft cd-frm">
                                    <h3 className="tdh">Template Detail</h3>

                                    <div className="col-12 tdm-in">
                                        <h6>Template Name</h6>
                                        <div className="form-group">
                                            <input name="template_name" onChange={this.onChanges} type="text" className="form-control" placeholder="Template Name"  />
                                             <span className="error" style={{color: "red"}}>{this.state.err_temp_add["template_name"]}</span>

                                        </div>
                                        <h6 className="mt-4">Total Invoice Value</h6>
                                        <h3 className="tdh mb-0"><NumberFormat
                                            value={(Math.round(total_amount * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={''}/></h3>


                                    </div>


                                    <button type="button" className="ext-btn" onClick={this.saveTemplete}>Save Template &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                    {/*    <button type="button" className="ext-btn ext-t2">Delete Invoice &nbsp;&nbsp;<i
                                     className="fas fa-trash-alt"></i></button>*/}
                                  {/*  <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                        Help with the System?</a>*/}
                                </div>
                                <div className="col-md-8 rht cd-il">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h3 className="tdh cup" data-toggle="modal" data-target="#create-template-modal"
                                                data-dismiss="modal" onClick={this.viewModalInvoice}>Add Template Items &nbsp;&nbsp;<i
                                                className="fas fa-folder-plus"></i></h3>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 details-box d-inv-box">
                                            <div className="col-12 dbx-in">

                                                <div className="row row_clr dit-in">
                                                    <div className="col-12 rht img-box p-0">

                                                        <div className='rg-container'>
                                                            <table className='transactions-table invoice-table' summary='Hed'>
                                                                <thead>
                                                                <tr>
                                                                    <th className=''>#</th>
                                                                    <th className=''>Title & Description</th>
                                                                    <th className=''>Rate/ITEM</th>
                                                                    <th className=''>qty</th>
                                                                    <th className=''>total</th>
                                                                    <th className=''></th>
                                                                    <th className=''></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {invoice_array && invoice_array.length?(
                                                                    invoice_array.map((list, key) =>
                                                                    {
                                                                        return (
                                                                            <tr className=''>
                                                                                <td className='' data-title='#'>{key+1}</td>
                                                                                <td className='' data-title='Title & Description'>{list.product_name}
                                                                                </td>
                                                                                <td className='' data-title='Rate/ITEM'>LKR {list.product_rate}</td>
                                                                                <td className='' data-title='qty'>{list.product_quantity}</td>
                                                                                <td className='' data-title='qty'><NumberFormat
                                                                                    value={(Math.round(list.sub_total * 100) / 100).toFixed(2)}
                                                                                    displayType={'text'} thousandSeparator={true} prefix={''}/></td>
                                                                                <td className='' data-title='qty'><i
                                                                                    className="fas fa-pencil-alt" onClick={()=>(this.viewModalInvoiceSaveEditor(key))}></i></td>

                                                                            </tr>
                                                                        )
                                                                    })
                                                                ):(
                                                                    <tr className=''>
                                                                        <td colSpan="5" className='' data-title='#'></td>
                                                                    </tr>

                                                                )
                                                                }
                                                                {/* <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''>VAT/NBT (+ 15%)</td>
                                                                    <td className='' data-title=''>LKR {this.state.tax_payment}</td>
                                                                    <td className='' data-title=''><i
                                                                        className="fas fa-pencil-alt"></i></td>
                                                                </tr>
                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''>Delivery Charges</td>
                                                                    <td className='' data-title=''>LKR {this.state.delivery_charge}</td>
                                                                    <td className='' data-title=''><i
                                                                        className="fas fa-pencil-alt"></i></td>
                                                                </tr>*/}
                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''>Total </td>
                                                                    <td className='' data-title=''> <NumberFormat
                                                                        value={(Math.round(total_amount * 100) / 100).toFixed(2)}
                                                                        displayType={'text'} thousandSeparator={true} prefix={''}/></td>
                                                                    <td className='' data-title=''></td>
                                                                </tr>

                                                                </tbody>
                                                            </table>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                             <span className="error" style={{color: "red"}}>{this.state.err_temp_add["table_fill"]}</span>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

                {/*  Product => Save*/}
                <Modal
                    show={this.state.modalShowAddInvoice}
                    onHide={this.state.setModalShowAddInvoice}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="ani-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseInvoice}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">{this.state.modalHeadText}</h3>


                                    <div className="col-12 tdm-in p-0">

                                        <h6>Title & Description</h6>
                                        <div className="form-group">
                                            <Autocomplete
                                                style={{marginTop:"-32px",marginBottom: "10px"}}
                                                options={this.state.product_data}
                                                getOptionLabel={(option) => option.item_name}
                                                renderInput={(params) => <TextField {...params} label="Title & Description"
                                                                                    margin="normal" value={this.state.product_name}

                                                                                    label={"Product Name " + this.state.product_name}
                                                />}
                                                freeSolo
                                                noOptionsText={''}
                                                defaultValue={this.state.product_name}
                                                //value={this.state.product_name}
                                                onInputChange={this.onChangesAuto}
                                                onChange={
                                                    (event, newValue) => {
                                                        if (newValue) {
                                                            this.setState({
                                                                product_rate: newValue.product_price,
                                                                option_index:event.target.getAttribute('data-option-index')
                                                            });
                                                            // this.getUserData(newValue.description);
                                                        }
                                                    }}
                                            />
                                        </div>
                                         <span className="error" style={{color: "red"}}>{this.state.errors_product_add["product_name"]}</span>
                                        <h6>Rate/Item</h6>
                                        <div className="form-group">
                                            <input type="text" name="product_rate" value={this.state.product_rate} className="form-control" placeholder="Enter Product Rate" onChange={this.onChangeNumber}/>
                                        </div>
                                         <span className="error" style={{color: "red"}}>{this.state.errors_product_add["product_rate"]}</span>
                                        <h6>Qty</h6>
                                        <div className="form-group">
                                            <input type="text" name="product_quantity"  value={this.state.product_quantity} className="form-control" placeholder="Enter Quantity" onChange={this.onChangeNumber}/>
                                        </div>
                                         <span className="error" style={{color: "red"}}>{this.state.errors_product_add["product_quantity"]}</span>
                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.state.productUpdateOrCreate}>{this.state.modelButtonText} &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                {/*    <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                        Help with the System?</a>*/}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* Product Edit => Edit*/}
                <Modal
                    show={this.state.modalShowAddInvoiceEdit}
                    onHide={this.state.setModalShowAddInvoiceEdit}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="ani-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseInvoiceEdit}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">{this.state.modalHeadText}</h3>


                                    <div className="col-12 tdm-in p-0">

                                        <h6>Title & Description</h6>
                                        <div className="form-group">

                                            <Autocomplete
                                                style={{marginTop:"-32px",marginBottom: "10px"}}
                                                options={this.state.product_data}
                                                getOptionLabel={(option) => option.item_name}
                                                renderInput={(params) => <TextField {...params} label="Title & Description"
                                                                                    margin="normal" value={this.state.product_name_edit}

                                                                                    label={"Product Name " + this.state.product_name_edit}
                                                />}
                                                freeSolo
                                                noOptionsText={''}
                                                defaultValue={this.state.product_name_edit}
                                                //value={this.state.product_name}
                                                onInputChange={this.onChangesAutoEdit}
                                                onChange={
                                                    (event, newValue) => {
                                                        if (newValue) {
                                                            this.setState({
                                                                product_rate_edit: newValue.product_price,
                                                                option_index:event.target.getAttribute('data-option-index')
                                                            });
                                                            // this.getUserData(newValue.description);
                                                        }
                                                    }}
                                            />
                                        </div>

                                         <span className="error" style={{color: "red"}}>{this.state.errors_product_edit["product_name_edit"]}</span>

                                        <h6>Rate/Item</h6>
                                        <div className="form-group">
                                            <input type="text" name="product_rate_edit" value={this.state.product_rate_edit} className="form-control" placeholder="Enter Product Rate" onChange={this.onChangeNumber}/>
                                        </div>
                                         <span className="error" style={{color: "red"}}>{this.state.errors_product_edit["product_rate_edit"]}</span>
                                        <h6>Qty</h6>
                                        <div className="form-group">
                                            <input type="text" name="product_quantity_edit"  value={this.state.product_quantity_edit} className="form-control" placeholder="Enter Quantity" onChange={this.onChangeNumber}/>
                                        </div>
                                         <span className="error" style={{color: "red"}}>{this.state.errors_product_edit["product_quantity_edit"]}</span>
                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.state.clickEvent}> {this.state.modelButtonText} &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                {/*    <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                        Help with the System?</a>*/}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <div id="plus-button" onClick={this.addRemoveActive}>
                    <i className="fas fa-plus"></i>
                </div>

                <div id="plus-menu">
                    <h4><i className="fas fa-plus"></i> Create New</h4>
                    <div className="pm-in">
                        <ul>
                            <li data-toggle="modal" data-target="#add-new-item-modal" className="l-item" onClick={this.viewModal}>
                                <div className="icn"><i className="fas fa-socks"></i></div>
                                <p className="tp">Add New Template</p>
                                <p className="bp">Add a new template to your store</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <Navigation pathname="all-invoices"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="reports-metrics"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link className="nav-link " id="tt-tab" data-toggle="tab" to="../all-invoices" role="tab"
                                          aria-controls="tt" aria-selected="true"><span className="tt">All Invoices</span><span
                                        className="amt">
                                        <NumberFormat
                                            value={(Math.round(total_invoice_lkr * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={''}/>{this.state.usd_available?(<> | <NumberFormat
                                        value={(Math.round(total_invoice_usd * 100) / 100).toFixed(2)}
                                        displayType={'text'} thousandSeparator={true} prefix={'USD '}/></>):('')}
                                    </span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" id="tr-tab" data-toggle="tab" to="../invoice-product-service" role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Products</span><span
                                        className="amt">{total_items} Items</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active" id="tp-tab" data-toggle="tab" to="../invoice-templetes" role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Templates</span><span className="amt">{total_templates} Templates</span></Link>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tp" role="tabpanel" aria-labelledby="tr-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Template Name</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Template Name" name="template_name_search" value={this.state.template_name_search} onChange={this.onChanges}/>
                                                        </div>
                                                        {/*<div className="form-group">
                                                            <label htmlFor="">Product Search</label>
                                                            <div className="slct">
                                                                <select name="" id="c1" className="form-control">
                                                                    <option value="LKR &amp; USD">All</option>
                                                                </select>
                                                            </div>
                                                        </div>*/}
                                                        <div className="form-group">
                                                            <label htmlFor="">Amount</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Value" name="amount_search" value={this.state.amount_search} onChange={this.onChanges}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" onClick={this.searchTemplate} className="srch-btn">Search</button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn clear-btn" onClick={this.clearSearch}>Clear</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className='rg-container'>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Template Name</th>
                                                        {/*<th className=''>Service/Product</th>*/}
                                                        <th className=''>Inclusive Items</th>
                                                        <th className=''>Amount</th>
                                                        <th className=''></th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.template_data_render}
                                                    </tbody>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"PREV"}
                                                    nextLabel={"NEXT"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />

                    </div>
                </div>
            </React.Fragment>

        );
    }

}


