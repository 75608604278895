import Tab from "react-bootstrap/Tab";
import React, {useContext, useRef, useState} from "react";
import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";
import {CreateTransactionModal} from "../TransactionManagement/CreateTransaction";
import { PostCompnentWebxBpsp } from "../../../PostCompnent";
import { useEffect } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";


export function ToggleTransactionCreation(props){
    const [productList, setToList] = useState([]);
    const [convinceFeeAcceptedBy, setConvinceFeeAcceptedBy] = useState("");
    const [show,setShow]=useState(false);
    const [eventK, setEventK] = useState("first");
    const [evnentName, setEventName] = useState("Supplier Information");
    const [subTotal, setSubTotal] = useState(0.00);
    const [transactionAmount, setTransactionAmount] = useState(0.00);
    const [settlementAmount, setSettlementAmount] = useState(0.00);
    const [merchantFee, setMerchantFee] = useState(0.00);
    const [supplierFee, setSupplierFee] = useState(0.00);
    const [convenienceFee, setconvenienceFee] = useState(0.00);

    return <>
        <button type="button" className="ext-btn" onClick={()=>setShow(true)}>New Transaction</button>
        <CreateTransactionModal
            show={show}
            onHide={()=>{
                setShow(false)
                setEventK("first")
                setEventName("Supplier Information")
                setToList([])
                setSubTotal(0.00)
                setConvinceFeeAcceptedBy("")
                setTransactionAmount(0.00)
                setSettlementAmount(0.00)
                setMerchantFee(0.00)
                setSupplierFee(0.00)
                setconvenienceFee(0.00)
            }}
            setEventK={setEventK}
            setEventName={setEventName}
            eventK={eventK}
            evnentName={evnentName}
            productList={productList}
            convinceFeeAcceptedBy={convinceFeeAcceptedBy}
            setToList={setToList}
            supplierData={props.supplierData}
            subTotal={subTotal}
            setSubTotal={setSubTotal}
            setConvinceFeeAcceptedBy={setConvinceFeeAcceptedBy}
            transactionAmount={transactionAmount}
            settlementAmount={settlementAmount}
            merchantFee={merchantFee}
            supplierFee={supplierFee}
            convenienceFee={convenienceFee}
            setTransactionAmount={setTransactionAmount}
            setSettlementAmount={setSettlementAmount}
            setMerchantFee={setMerchantFee}
            setSupplierFee={setSupplierFee}
            setconvenienceFee={setconvenienceFee}
        />
    </>
}

export function SupplierLeftPane(props) {


   let value=false;
   if(props.supplierData){
    if(props.supplierData.status==1){
        value=true;
    }
   }
    const [checked, setChecked] = React.useState(value);
    const [transactionInfo, setTransactionInfo] = React.useState({
        total_transaction:0.00,
        last_transaction_date:""

    });

   useEffect(() => {
    let formData=new FormData();
    formData.append("supplier_id",props.supplierData.supplier_id);
    formData.append("store_id",sessionStorage.getItem("merchant_id"));
    PostCompnentWebxBpsp("getMerchantBpspSuppliersWithTransactionTotal",formData).then(response => {

            setTransactionInfo({
                total_transaction:response.data[0].total_transaction,
                last_transaction_date:response.data[0].last_transaction_date==null?" - ":moment(response.data[0].last_transaction_date).format('ll'),
            })
    });
   }, [])


    const handleChange = () => {
      setChecked(!checked);

      let formData=new FormData();
      formData.append("supplier_id",props.supplierData.supplier_id);
      formData.append("store_id",sessionStorage.getItem("merchant_id"));
      formData.append("status",!checked==true?1:0);
      PostCompnentWebxBpsp("updateBpspSupplierStatus", formData).then(response => {

    });
    };




   // console.log(props.supplierData);
    return    <div className="col-md-4 lft">
        <h4 className="modal-title" id="activation-modal-label">{props.supplierData.business_name}</h4>
        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
             aria-orientation="vertical">
            <div className="sf-box transparent-sf-box mb-3">
                <p className="ptp">Total Transaction Value</p>
                <p className="am">
                <NumberFormat
                value={transactionInfo.total_transaction}
                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
                    </p>
                <p className="ptp">Last Transaction Date</p>
                <p className="am sam">{transactionInfo.last_transaction_date}</p>

            </div>
            <div className="row">
                <div className="col-md-7">
                    {/*//inactive-badge*/}
                    <h5>

                        {checked==true? (<Badge className="lg-badge">ACTIVE SUPPLIER</Badge>):(<Badge className="inactive-badge">INACTIVE SUPPLIER</Badge>)}


                    </h5>
                </div>
                <div className="col-md-2">
                    <label className="switch mt-05">
                        <input type="checkbox"  checked={checked}
        onChange={handleChange}/>
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
            <Nav variant="pills" className="flex-column">
                <Nav.Item>
                    <Nav.Link className="nav-link" eventKey="first"><i className="fa fa-angle-right"
                                                                       aria-hidden="true"></i> Supplier
                        Information </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="nav-link" eventKey="second" onClick={() => props.clickTransaction.current.click()}><i
                        className="fa fa-angle-right" aria-hidden="true"></i> All Transaction
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="nav-link" eventKey="third" onClick={() => props.clickSupplier.current.click()}><i
                        className="fa fa-angle-right" aria-hidden="true"></i> All Invoices
                    </Nav.Link>
                </Nav.Item>

            </Nav>
        </div>

        <ToggleTransactionCreation supplierData={props.supplierData}/>
        <button type="button" className="nxt-btn" onClick={() => props.submitRefs.current.click()}>Save & Exit</button>
      {/*  <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need a
            hand
            figuring it out?</a>*/}
    </div>
}