import Tab from "react-bootstrap/Tab";
import React, { useContext, useEffect, useState } from "react";

import { Iframe } from "../../common/Iframe";

import {HostedSession} from "../../Subscriptions/webxpay.hostedsession";
import {myConfig} from "../../../config";

import {ViewSupplierTransactionReceipt} from "../SupplierIncludes/TransactionManagement/ViewSupplierTransactionReceipt";
import {PayNow} from "../SupplierIncludes/TransactionManagement/PaymentHandler";
import {supplierIdContext} from "../SupplierIncludes/SupplierManagement/LoadModalFunctions";
import {PostComponentToken, PostWebxLaravel} from "../../PostCompnent";
import {toast} from "react-toastify";

export function CreateNewTransaction() {
    const [modalShow, setModalShow] = React.useState(false);


    return (
        <>
            <div onClick={() => setModalShow(true)}>
                <div style={{ color: "#e6562fe6" }}>
                    <strong> + Add New Transaction </strong>
                </div>
            </div>

            <ViewSupplierTransactionReceipt
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export function LoadIndividualTransaction() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <div onClick={() => setModalShow(true)}>...</div>

            <ViewSupplierTransactionReceipt
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export function PaymentInformationData(props) {
    const supplier_id = useContext(supplierIdContext);
    const [currency,setCurrencyData]=useState('');
    const [secureUrl,secure3dResponseURL]=useState('');
    const [bankMid,setBankMid]=useState('');
    const [randomString,setRandomString]=useState('');
    const [token_data,setTokenData]=useState('');
    const [businessData,setBusinessData]=useState('');

    useEffect(() => {
        getTokenData();
    }, []);

    function popupWindow(url, windowName, win, w, h) {
        const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
        const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
        return win.open(
            url,
            windowName,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        );
    }

    function getTokenData(){
        sessionStorage.setItem("session_gererate","0")
        let formDatas=new FormData();
        PostWebxLaravel("get-auth-token-bpsp", formDatas).then(response => {
            generateSessionData(response.data.token,response.data.currency,response.data.secure3dResponseURL,response.data.bankMID,response.data.reference);
            getSavedCards(response.data.token)

            setTokenData(response.data?response.data.token:'')
            setCurrencyData(response.data?response.data.currency:'')
            secure3dResponseURL(response.data?response.data.secure3dResponseURL:'')
            setBankMid(response.data?response.data.bankMID:'')
            setRandomString(response.data?response.data.reference:'')
        });
    }

    const handleErrors = error => {
        document.getElementById('save-card-button').disabled = false;
        document.getElementById('save-card-button').innerHTML = 'Save';

        document.querySelectorAll('.err').forEach(element => (element.innerHTML = ''));
        document.querySelectorAll('.general-error').forEach(element => (element.innerHTML = ''));

        switch (error.type) {
            case 'fields_in_error': {
                const { cardNumber, expiryMonth, expiryYear, securityCode } = error.details;

                if (cardNumber) {
                    if (cardNumber === 'missing') {
                        document.querySelector('.card-number-error').innerHTML = 'Enter valid card number';
                    }
                    if (cardNumber === 'invalid') {
                        document.querySelector('.card-number-error').innerHTML = 'Invalid card number';
                    }
                }
                if (expiryMonth) {
                    if (expiryMonth === 'missing') {
                        document.querySelector('.exp-month-error').innerHTML = 'Enter expiration month';
                    }
                    if (expiryMonth === 'invalid') {
                        document.querySelector('.exp-month-error').innerHTML = 'Invalid expiration month';
                    }
                }
                if (expiryYear) {
                    if (expiryYear === 'missing') {
                        document.querySelector('.exp-year-error').innerHTML = 'Enter expiration year';
                    }
                    if (expiryYear === 'invalid') {
                        document.querySelector('.exp-year-error').innerHTML = 'Invalid expiration year';
                    }
                }
                if (securityCode) {
                    if (securityCode === 'missing') {
                        document.querySelector('.cvv-error').innerHTML = 'Enter CVV';
                    }
                    if (securityCode === 'invalid') {
                        document.querySelector('.cvv-error').innerHTML = 'Invalid CVV';
                    }
                }
                console.error('missing card details', error.details);
                break;
            }
            case 'request_timeout': {
                document.querySelectorAll('.general-error').forEach(element => {
                    element.innerHTML = `<span class="text-decoration-uppercase">${error.details}</span>`;
                });
                console.error('request time out', error.details);
                break;
            }
            case 'system_error': {
                if (error.details === 'cvv missing') {
                    document.querySelectorAll('.general-error').forEach(element => {
                        element.innerHTML = 'Enter CVV details';
                    });
                } else {
                    document.querySelectorAll('.general-error').forEach(element => {
                        element.innerHTML = error.details;
                    });
                }
                console.error('system error', error.details);
                break;
            }
            default:
                break;
        }
    };
    function onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    function getSavedCards(token_datas){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' + token_datas
            },

        };
        let customer = {
            'id': sessionStorage.getItem("merchant_id"),
            'email':sessionStorage.getItem("user_email"),
        };

        let data = JSON.stringify({
            'customer': customer,
        });

        PostComponentToken("cards/get", data, config).then(response => {
            console.log(response.data)
        });
    }

    const handleSuccess = (sessionId,token,
                           currency,
                           secure3dResponseURL,
                           bankMID,
                           reference) => {
        console.log('session id', sessionId+' Vasda '+ token+' Vweq '+ currency+' Srrr '+ secure3dResponseURL+' Rttt  '+bankMID+' Boo '+ reference);


        var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

        // this.handleToggleProgress();
        let BaseUrl = myConfig.apiUrl;

        // props.businessData.email_address
        console.log(props);
        let customer = {
            'id': sessionStorage.getItem("merchant_id"),
            'email':sessionStorage.getItem("user_email"),
            'firstName': "james",
            'lastName': "gordan",
            'contactNumber': "colombo",
            'postalCode': "78151",
            'country': "srilanka",
        };

        let data = JSON.stringify({
            'customer': customer,
            'currency': currency,
            'bankMID': bankMID,
            'secure3dResponseURL': secure3dResponseURL+'?reference_number='+reference,
            'session': sessionId,
        });

        //let formData = new FormData();    //formdata object

        // formData.append(JSON.stringify(data));


        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' + token
            },

        };

        PostComponentToken("cards/save3ds", data, config).then(response => {
            if (response.data.error == true) {
                if (response.data.type == "3ds") {

                    let BaseUrl = myConfig.laravelWeb;
                    let formData = new FormData();
                    const htmlData = Buffer.from(response.data.html3ds).toString('base64');
                    formData.append('paymentHtml', htmlData);
                    formData.append('customer', JSON.stringify(customer));
                    formData.append('reference_number', reference);

                    // Call the refactored function to handle convert-payment-view
                    handleConvertPaymentView("convert-payment-view", formData,BaseUrl);
                }
                toast(response.data.explanation);
            } else {
                if (response.data['html3ds']) {
                    let BaseUrl = myConfig.laravelWeb;
                    let formData = new FormData();
                    const htmlData = Buffer.from(response.data.html3ds).toString('base64');
                    formData.append('paymentHtml', htmlData);
                    formData.append('customer', JSON.stringify(customer));
                    formData.append('reference_number', reference);

                    // Call the refactored function to handle subscription-api/convert-payment-view
                    handleConvertPaymentView("subscription-api/convert-payment-view", formData,BaseUrl);
                }
            }

            document.getElementById('save-card-button').disabled = false;
            document.getElementById('save-card-button').innerHTML = 'Save';
        });
    }
// Define a function to handle the convert-payment-view API call and popup
    function handleConvertPaymentView(url, formData, BaseUrl) {
        return PostWebxLaravel(url, formData).then(response => {
            console.log(response)
            popupWindow(
                BaseUrl + "load-secure-otp/" + response.data,
                "test",
                window,
                1000,
                800
            );

            // Handle the timer logic to check the payment status
            const timer = setInterval(() => {
                let statusFormData = new FormData(); // formdata object for checking status
                statusFormData.append("reference_number", formData.get('reference_number'));
                PostWebxLaravel("token/get-payment-status", statusFormData).then(response => {
                    if (response.data.status == 1) {
                        clearInterval(timer);
                        window.location.reload();
                    } else if (response.data.status == 2) {
                        alert("Card not added");
                        clearInterval(timer);
                        window.location.reload();
                    }
                });
            }, 5000);
        });
    }

    function generateSessionData(token, currency, secure3dResponseURL, bankMID, reference) {
        // Get values from form fields (you can change these selectors if needed)
        let card = {
            number: "#card-number",
            securityCode: "#security-code",
            expiryMonth: "#expiry-month",
            expiryYear: "#expiry-year",
            nameOnCard: "#cardholder-name",
        };

        // Function that runs after initialization
        const afterInit = GenerateSession => {

            const handleSaveCardClick = () => {

                // Disable button to prevent multiple submissions
                document.getElementById('save-card-button').disabled = true;

                // Generate session and handle success or error
                GenerateSession(
                    session => handleSuccess(session, token, currency, secure3dResponseURL, bankMID, reference),
                    error => handleErrors(error)
                );
            };

            // Add event listener to save-card-button for the click event
            document.getElementById('save-card-button').addEventListener('click', handleSaveCardClick);

            // Optionally, return a cleanup function to remove event listener
            return () => {
                document.getElementById('save-card-button').removeEventListener('click', handleSaveCardClick);
            };
        };

        // Check if PaymentSession is available

        if (window.PaymentSession) {
            const hostedSession = new HostedSession();
            hostedSession.configure(card, afterInit);
        } else {

            console.error("Bank's payment session not initialized, please check the sample codes webxpay provided");
        }
    }

    let lend = 0;
    return (
        <Tab.Pane eventKey="second">
            <div className="row pay-opts">
                <div className="col-12">
                    <h5>Payment Information</h5>
                </div>


                <div className="col-12">
                    <div className="tab-content" id="nav-tabContent">

                        <div
                            className="tab-pane fade show active"
                            id="pay-online"
                            role="tabpanel"
                            aria-labelledby="nav-pay-online-tab"
                        >
                            <div className="row administrator-information">
                                <div className="col-12">

                                    <p className="p-us">{/*Pay/ Save &*/} Pay Using <img src="images/cards/4.png" alt=""/> Card <img
                                        src="images/cards/13.png" alt=""/></p>
                                    <form className="row administrator-information">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Cardholder Name:</label>
                                                <input type="text" id="cardholder-name" className="form-control"
                                                       title="card-holder name"
                                                       onChange={onChanges}
                                                       readOnly/>
                                                <span className="text-danger card-holder-error err"></span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Card Number:</label>
                                                <input type="text" id="card-number" className="form-control"
                                                       title="card number"
                                                       onChange={onChanges}
                                                       readOnly/>
                                                <span className="text-danger card-number-error err"></span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Expiry Month: <small>(MM)</small></label>
                                                <input type="text" id="expiry-month" className="form-control"
                                                       title="expiry month"
                                                       onChange={onChanges}
                                                       readOnly/>
                                                <span className="text-danger exp-month-error err"></span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Expiry Year: <small>(YYYY)</small></label>
                                                <input type="text" id="expiry-year" className="form-control"
                                                       title="expiry year"
                                                       onChange={onChanges}
                                                       readOnly/>
                                                <span className="text-danger exp-year-error err"></span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Security Code:</label>
                                                <input type="text" id="security-code" className="form-control"
                                                       title="security code"
                                                       onChange={onChanges}
                                                       readOnly/>
                                                <span className="text-danger cvv-error err"></span>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-12 pcnt1">
                                            <div className="row">
                                                <div className="col-12 pcond">
                                                    <p>Save my card details for future transactions</p>
                                                    <label className="cont">
                                                        <input type="checkbox"/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>*/}
                                        <div className="col-md-12 pcnt1">
                                            <button className="sub-btn" id="save-card-button">Save Card</button>
                                        </div>
                                    </form>


                                    <div className="row row_clr fm">
                                        <div class="col-12 fm-in">
                                            {/*<p className="p-us">*/}
                                            {/*      You may proceed to make payment by selecting the 'Pay with Visa' option or opt out by selecting the 'Pay Later' option and make an payment at your convenience.*/}
                                            {/*</p>*/}
                                            {/* <ul class="clist">
                        <li>
                          <img
                            src={require("../../../../images/cards/1.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/2.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/3.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/4.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/5.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/6.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/7.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/8.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/9.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/10.png")}
                            alt=""
                          />
                        </li>
                      </ul> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="pay-offline"
                            role="tabpanel"
                            aria-labelledby="nav-pay-offline-tab"
                        >
                            <div className="row offline-pay">
                                <div className="col-12 oft">
                                    <h4>For Cheque Payments</h4>
                                </div>
                                <div className="col-md-6">
                                    <p>Please make the cheque in favor of</p>
                                    <h3>WEBXPAY (Pvt) Ltd.</h3>
                                </div>
                                <div className="col-md-6">
                                    <p>Send the cheque via Registered Post to</p>
                                    <p>
                                        <b>
                                            5th Floor, Green Lanka Tower, No. 46/46, Navam Mawatha,
                                            Colombo 02, Sri Lanka.
                                        </b>
                                    </p>
                                    <p>
                                        <b>
                                            Or <a href="tel:+94117441900">Call (+94) 117 441 900</a>{" "}
                                            to Collect
                                        </b>
                                    </p>
                                </div>
                                <div className="col-12 oft mt-4">
                                    <h4>For Direct Debits or Wire Transfers</h4>
                                    <p className="mb-3">
                                        Please find the account information as follows:
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <h3>1007 5396 0039</h3>
                                    <h4 className="mb-2">WEBXPAY (Pvt) Ltd.</h4>
                                    <p>
                                        5th Floor, Green Lanka Tower,
                                        <br />
                                        No. 46/46, Navam Mawatha,
                                        <br />
                                        Colombo 02, Sri Lanka.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <ul className="b-list">
                                        <li>
                                            <span>Bank Name</span>: Sampath Bank PLC
                                        </li>
                                        <li>
                                            <span>Address</span>: No. 19 A1, Kandy
                                        </li>
                                        <li>
                                            <span>Bank Code</span>: 7278
                                        </li>
                                        <li>
                                            <span>Branch No</span>: 007
                                        </li>
                                        <li>
                                            <span>SWIFT Code</span>: BSAMLKLX
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 oft mt-4">
                                    <h4>Proof of Payment</h4>
                                    <div className="upload">
                                        <img src="images/upload.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    );
}
