import Tab from "react-bootstrap/Tab";
import React, { useState } from "react";
import { ViewSupplierTransactionReceipt } from "./ViewSupplierTransactionReceipt";
import { useEffect } from "react";
import {GetWebxLaravel, PostCompnentWebxBpsp, PostWebxLaravel} from "../../../PostCompnent";
import moment from "moment";
import {myConfig} from "../../../../config";
import NumberFormat from "react-number-format";
import ReactPaginate from "react-paginate";
import {toast, ToastContainer} from "react-toastify";
import {ViewApprovalTransaction} from "./ViewApprovalTransaction";

export function CreateNewTransaction() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div onClick={() => setModalShow(true)}>
        <div style={{ color: "#e6562fe6" }}>
          <strong> + Add New Transaction </strong>
        </div>
      </div>

      <ViewSupplierTransactionReceipt
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export function LoadIndividualTransaction(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div onClick={() => setModalShow(true)}>...</div>

      <ViewSupplierTransactionReceipt
        show={modalShow}
        onHide={() => setModalShow(false)}
        invoiceId={props.invoiceId}
        supplierId={props.supplierId}
        
      />
    </>
  );
}
export function AllSupplierInvoices(props) {
  const [supplierInvoices, setSupplierInvoices] = useState([]);

    const [offsetInvoices, setOffsetInvoices] = React.useState(0);
    const [perPageInvoices, setPerPageInvoices] = React.useState(10);
    const [currentPageInvoices, setCurrentPageInvoices] = React.useState(0);
    const [pageCountInvoices, setPageCountInvoices] = React.useState(0);
    const [invoice_lists_render, setInvoiceListRender] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [invoiceDetail, setInvoiceDetail] = useState([]);
    const [invoiceLineItems, setInvoiceLineItems] = useState([]);
    const [invoiceReference, setInvoiceReference] = useState("");
    const [paymentData, setPaymentData] = useState("");

    useEffect(() => {

      loadSupplierInvoices();
  }, []);
    useEffect(() => {

        loadTableSupplierInvoices();
    }, [supplierInvoices,offsetInvoices,currentPageInvoices]);

  function loadSupplierInvoices(){
        let formData = new FormData();
        formData.append("store_id", sessionStorage.getItem("merchant_id"));
        formData.append("supplier_id", props.supplier_data.supplier_id);

        PostWebxLaravel("bpsp/get-supplier-invoices", formData).then((response) => {
            setSupplierInvoices(response.data.invoices);
        });
      loadTableSupplierInvoices();
    }


    const viewModalTransaction=(invoiceId)=>{

        GetWebxLaravel(
            "bpsp/get-invoice-data-and-items/" + invoiceId
        ).then((response) => {
            setInvoiceLineItems(response.data.line_items);
            setInvoiceReference(response.data.invoice_reference);
            setInvoiceDetail(response.data.invoice_data);
            setPaymentData(response.data.payment_process)
        });

        setModalShow(true);

    }


    const handlePageClickInvoices = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPageInvoices;
        setCurrentPageInvoices(selectedPage)
        setOffsetInvoices(offset)
    };

    const loadTableSupplierInvoices=()=>{
        const list_data =supplierInvoices;

        const zeroPad = (num, places) => String(num).padStart(places, '0');

        const slice = list_data.slice(offsetInvoices, offsetInvoices + perPageInvoices);



        const trans = list_data && list_data.length ? (slice.map((list, key) =>
            <tr className="">
                <td className="" data-title="#">
                    {key+1}
                </td>
                <td className="" data-title="#">
                    {list.inv_reference}
                </td>
                <td className="" data-title="#">
                    <NumberFormat
                        value={(Math.round(list.subtotal * 100) / 100).toFixed(2)}
                        displayType={'text'} thousandSeparator={true}
                        prefix='LKR '/>
                </td>
                <td className="" data-title="#">
                    {list.payment_status_id ==
                    2 ? (
                        <>
                              <span className="dot green">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Approved
                        </>
                    ) : (
                        ""
                    )}
                    {list.payment_status_id ==
                    3 ? (
                        <>
                              <span className="dot red">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Declined
                        </>
                    ) : (
                        ""
                    )}
                    {list.payment_status_id ==
                    1 ? (
                        <>
                              <span className="dot yellow">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Pending
                        </>
                    ) : (
                        ""
                    )}
                </td>
                <td className="" data-title="#">
                    {list.approval_status ==
                    1 ? (
                        <>
                              <span className="dot green">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Approved
                        </>
                    ) : (
                        ""
                    )}
                    {list.approval_status ==
                    2 ? (
                        <>
                              <span className="dot red">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Declined
                        </>
                    ) : (
                        ""
                    )}
                    {list.approval_status ==
                    0 ? (
                        <>
                              <span className="dot yellow">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Pending
                        </>
                    ) : (
                        ""
                    )}
                </td>
                <td className="" data-title="#">
                    {moment(list.created_at).format("ll")}
                </td>

                <td className="" data-title="#">
                  {/*  {list.payment_status_id ==
                    2 ? (
                        <>


                        </>
                    ) : (
                        ""
                    )}
                    {list.payment_status_id ==
                    3 ? (
                        <>
                            <button onClick={()=>callPayNow(list.id)} className="pn-btn">Pay Now</button>


                        </>
                    ) : (
                        ""
                    )}
                    {list.payment_status_id ==
                    1 ? (
                        <>

                            <button onClick={()=>callPayNow(list.id)} className="pn-btn">Pay Now</button>
                        </>
                    ) : (
                        ""
                    )}*/}

                    <div onClick={() => viewModalTransaction(list.id)}>
                        <button className="dot-drop btn"><i className="fas fa-eye" aria-hidden="true"></i></button></div>

                </td>

            </tr>
        )) : (
            <tr className=''>
                <td colSpan="6" className='' data-title='#'><strong>No results found</strong></td>
            </tr>
        );
        setPageCountInvoices(Math.ceil(list_data.length / perPageInvoices))
        setInvoiceListRender(trans);
    }
    function popupWindow(url, windowName, win, w, h) {
        const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
        const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
        return win.open(
            url,
            windowName,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        );
    }

    const callPayNow=(invoice_id)=>{
        let formData = new FormData(); //formdata object
        formData.append('invoice_id',invoice_id);
        console.log(invoice_id)

        PostWebxLaravel("bpsp/check-invoice", formData)
            .then((response) => {
                console.log(response);

                if (response.data.success == 200) {
                    if (response.data.status == 0) {
                        loadSupplierInvoices();
                        toast(response.data.response); // Call toast for status 0
                    } else {
                        loadSupplierInvoices();
                        toast(response.data.response); // Call toast for other statuses
                    }
                }
            })
            .catch((error) => {
                console.error("Error occurred:", error);
            });
    }

  let lend = 0;
  return (
      <>

          <ToastContainer/>
    <Tab.Pane eventKey="third">
        <ViewApprovalTransaction
            show={modalShow}
            onHide={() => setModalShow(false)}
            supplierId={props.supplier_data.supplier_id}
            invoiceDetail={invoiceDetail}
            invoiceLineItems={invoiceLineItems}
            invoiceReference={invoiceReference}
            paymentData={paymentData}
            loadSupplierInvoices={loadSupplierInvoices}
        />
      {lend == 0 ? (
        <div className="rg-container">
          {/*  <DataTable
                                            data={transaction_lists}
                                            columns={columns}
                                            striped={true}
                                            hover={true}
                                            responsive={true}
                                            onClickRow={click}
                                        />*/}
          <table className="transactions-table ttt-2" summary="Hed">
            <thead>
              <tr>
                <th className="">#</th>
                <th className="">Invoice Ref</th>
                <th className="">Value </th>
                <th className="">Payment Status</th>
                <th className="">Approval Status</th>
                <th className="">Invoice Created Date</th>
                <th className=""></th>

              </tr>
            </thead>
            <tbody>
                    {invoice_lists_render}
            </tbody>
          </table>
            <ReactPaginate
                previousLabel={"PREV"}
                nextLabel={"NEXT"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountInvoices}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickInvoices}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}/>
        </div>
      ) : (
        <div className="rg-container">
          <div className="col-sm-12">
            <img
              src={require("../../../../images/uh_no.png")}
              alt=""
              className="mi"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
          <div className="row">
            <strong className="mt-1 " style={{ textAlign: "center" }}>
             Invoice list is empty
            </strong>
          </div>
            <CreateNewTransaction />
        </div>
      )}
        <button ref={props.clickSupplier}  onClick={loadSupplierInvoices} style={{display:"none"}}></button>
    </Tab.Pane>
</>
  );
}
