import Tab from "react-bootstrap/Tab";
import React, { useContext, useRef, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";
import NumberFormat from "react-number-format";
import {myConfig} from "../../../../config";
import { supplierDetailContext } from "../SupplierManagement/SupplierModal";
import { useEffect } from "react";
import { GetWebxLaravel } from "../../../PostCompnent";


export function CreateTransactionLeftPane(props) {
   
    let currency_id=1;


    const[bank_detail,setBankDetail]=useState({
        bank_name:'',
        bank_branch:''
    });   
    useEffect(() => {

        GetWebxLaravel("bpsp/get-branch/" +  props.supplierData.bank_branch_id).then(response => {
                setBankDetail({bank_name:response.data.bank,bank_branch:response.data.bank_branch})
        });
    }, []);
  
     const runFunction = () => {
      
     }

    return    <div className="col-md-4 lft">
        <h4 className="modal-title" id="activation-modal-label"></h4>
        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
             aria-orientation="vertical">
            <h3 className="tdh"></h3>

            <div className="col-12 tdm-in">

                <h6>Transaction Value </h6>
                <h3 className="tdh"><NumberFormat
                    value={props.transactionAmount}
                    displayType={'text'} thousandSeparator={true}
                    prefix={'LKR '}/></h3>

                <h6>Handling Fee</h6>
                <h5 className="sh"><NumberFormat
                    value={props.convenienceFee}
                    displayType={'text'} thousandSeparator={true}
                    prefix={'LKR '}/></h5>

                <h6>Account Name</h6>
                <h5 className="sh">{props.supplierData.account_name}</h5>
                <h6>Account Number</h6>
                <h5 className="sh">{props.supplierData.account_number}</h5>
                <h6>Name of Bank</h6>
                <h5 className="sh">{bank_detail.bank_name}</h5>
                <h6>Bank Branch(Branch Code)</h6>
                <h5 className="sh">{bank_detail.bank_branch}</h5>

            
            </div>

            <Nav variant="pills" className="flex-column" >
                <Nav.Item>
                    <Nav.Link className="nav-link active" eventKey={props.eventK}><i className="fa fa-angle-right"
                                                                       aria-hidden="true"></i> {props.evnentName} </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                    <Nav.Link className="nav-link" eventKey="second"><i
                        className="fa fa-angle-right" aria-hidden="true"></i> Payment Information
                    </Nav.Link>
                </Nav.Item> */}

            </Nav>
        </div>
        {props.eventKey=="first"?(
            <button type="button" className="nxt-btn" onClick={() => props.myfunction.current.click()}>Save & Process</button>
        ):(     <button type="button" className="nxt-btn" onClick={() => props.myfunctionPayLater.current.click()}>PayLater</button>)}


      {/*  <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need a
            hand
            figuring it out?</a>*/}
    </div>
}