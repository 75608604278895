import axios from 'axios';
import {myConfig} from "../config";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import React from "react";
import {Navigate} from "react-router";
import axiosRetry from "axios-retry";
const proxyurl = '';

export function PostComponentToken(type,formData,lar_config){

    const axios = require('axios');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url:myConfig.tokenStaging+ type,
        headers: lar_config.headers,
        data : formData
    };

    return axios.request(config)
        .then(response => response)
            .catch(error => {
                console.log(error.response)
                // if (error.response){
                //     if (error.response.status==401){
                //         sessionStorage.removeItem('userToken');
                //         localStorage.removeItem('userToken');
                //         sessionStorage.removeItem('laravel_token');
                //         localStorage.removeItem('laravel_token');
                //         localStorage.removeItem('merchant_id');
                //         sessionStorage.removeItem('merchant_id');
                //         window.location.reload();
                //     }
                // }
            });


    /* let BaseUrl=myConfig.tokenStaging;


     return  axios.post(BaseUrl +type, formData,lar_config)
         .then(response => response)
         .catch(error => {
             console.log(error.response)
             // if (error.response){
             //     if (error.response.status==401){
             //         sessionStorage.removeItem('userToken');
             //         localStorage.removeItem('userToken');
             //         sessionStorage.removeItem('laravel_token');
             //         localStorage.removeItem('laravel_token');
             //         localStorage.removeItem('merchant_id');
             //         sessionStorage.removeItem('merchant_id');
             //         window.location.reload();
             //     }
             // }
         });*/

}
export function PostCompnentWebx (type,formData) {

        let BaseUrl = myConfig.laravelUrl+'server-post/';
       var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
       const config = {
           headers: {
               //'content-type': 'undefined',
               'Authorization': 'Bearer' + ' ' + userToken
           }
       };
    const condition = navigator.onLine ? 'online' : 'offline';

    if (condition=="online") {
        axiosRetry(axios, { retries: 2 });

        return  axios.post(BaseUrl + type, formData, config)

            .then(response => response)

            .catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        sessionStorage.removeItem('userToken');
                        localStorage.removeItem('userToken');
                        sessionStorage.removeItem('laravel_token');
                        localStorage.removeItem('laravel_token');
                        localStorage.removeItem('merchant_id');
                        sessionStorage.removeItem('merchant_id');
                        window.location.reload();
                    }
                }
            });
    }else{

        const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        if (lastItem!=="error") {
            sessionStorage.setItem("last_url", lastItem);
        }
        setJunk();

    }

}

export function PostCompnentWebxBpsp (type,formData) {

    let BaseUrl = myConfig.laravelUrl+'server-post-bpsp/';
    var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
    const config = {
        headers: {
            //'content-type': 'undefined',
            'Authorization': 'Bearer' + ' ' + userToken
        }
    };
    const condition = navigator.onLine ? 'online' : 'offline';

    if (condition=="online") {
        axiosRetry(axios, { retries: 2 });

        return  axios.post(BaseUrl + type, formData, config)

            .then(response => response)

            .catch(error => {
                if (error.response) {

                    if (error.response.status == 401) {
                        sessionStorage.removeItem('userToken');
                        localStorage.removeItem('userToken');
                        sessionStorage.removeItem('laravel_token');
                        localStorage.removeItem('laravel_token');
                        localStorage.removeItem('merchant_id');
                        sessionStorage.removeItem('merchant_id');
                        window.location.reload();
                    }
                }
            });
    }else{

        const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

        if (lastItem!=="error") {
            sessionStorage.setItem("last_url", lastItem);
        }
        setJunk();

    }

}


function setJunk(){
    window.location.replace("http://localhost:3000/error");
    // window.location.href = 'http://localhost:3000/#/error';
    window.location.reload();
}

export function PostWebxLaravel(type,userData) {

    let BaseUrl = myConfig.laravelUrl;
    let lar_config = {
        headers: {
           // 'content-type': 'undefined',
            'Content-Type': 'multipart/form-data',
            'Authorization': sessionStorage.getItem("laravel_token"),
            'Access-Control-Allow-Origin': '*', // Note: This doesn't work if server doesn't set the CORS headers

        },
    };
    return  axios.post(BaseUrl +type, userData,lar_config)
        .then(response => response)
        .catch(error => {
            if (error.response){
                if (error.response.status==401){
                    sessionStorage.removeItem('userToken');
                    localStorage.removeItem('userToken');
                    sessionStorage.removeItem('laravel_token');
                    localStorage.removeItem('laravel_token');
                    localStorage.removeItem('merchant_id');
                    sessionStorage.removeItem('merchant_id');
                    window.location.reload();
                }
            }
        });
}

export function PostComponentTokenDelete(type,formData,lar_config){

    const axios = require('axios');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url:myConfig.tokenStaging+ type,
        headers: lar_config.headers,
        data : formData
    };

    return axios.request(config)
        .then(response => response)
        .catch(error => {
            console.log(error.response)

        });



}

export function GetWebxLaravel(type,userData) {

    let BaseUrl = myConfig.laravelUrl;
    let lar_config = {
        headers: {
           // 'content-type': 'undefined',
            'Authorization': sessionStorage.getItem("laravel_token"),

        },
    };

    return axios.get(BaseUrl + type,lar_config)
        .then(response => response)
        .catch(error => {
            if (error.response){
                if (error.response.status==401){
                    sessionStorage.removeItem('userToken');
                    localStorage.removeItem('userToken');
                    sessionStorage.removeItem('laravel_token');
                    localStorage.removeItem('laravel_token');
                    localStorage.removeItem('merchant_id');
                    sessionStorage.removeItem('merchant_id');
                    window.location.reload();
                }
            }

        });
}

export function addZeroes(num) {
    const dec = num.split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)

}

export function  changeNumber(e){

    var val = e;
    var re = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)$/g;
    var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
    if (re.test(val)) {
        //do something here

    } else {
        val = re1.exec(val);
        if (val) {
            e = val[0];
        } else {
            e = "";
        }
    }
    return e;
}