import React, {useState} from "react";
import {CreateTransactionModal} from "../SupplierIncludes/TransactionManagement/CreateTransaction";
import {Link} from "react-router-dom";
import {TokenizedPayment} from "./TokenizedPayment";



export function ToggleTokenization(props){
    const [productList, setToList] = useState([]);
    const [convinceFeeAcceptedBy, setConvinceFeeAcceptedBy] = useState("");
    const [show,setShow]=useState(false);
    const [eventK, setEventK] = useState("first");
    const [evnentName, setEventName] = useState("Supplier Information");

    return <>
        <Link to="" className="nav-link " id="tt-tab" data-toggle="tab" onClick={()=>setShow(true)}
              role="tab"
              aria-controls="tt" aria-selected="true"><span
            className="tt">Tokenize card</span>
            <span
                className="amt">Save Card</span>

        </Link>

        <TokenizedPayment
            show={show}
            onHide={()=>{
                setShow(false)
                setEventK("first")
                setEventName("Supplier Information")
                setToList([])
                setConvinceFeeAcceptedBy("")
                window.location.reload();
            }}
            setEventK={setEventK}
            setEventName={setEventName}
            eventK={eventK}
            evnentName={evnentName}
            productList={productList}
            convinceFeeAcceptedBy={convinceFeeAcceptedBy}
            setToList={setToList}
            supplierData={props.supplierData}
            setConvinceFeeAcceptedBy={setConvinceFeeAcceptedBy}
        />
    </>
}