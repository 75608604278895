import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import "../../../../css/bootstrap.min.css";
import "../../../../css/supplier_modal/master-model.css";
import "../../../../css/supplier_modal/screen-model.css";
import "../../../../css/supplier_modal/switch.css";
import NumberFormat from "react-number-format";
import {
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import LoadingSpinner from "../../../LoadingSpinner";
import { myConfig } from "../../../../config";
import {
    GetWebxLaravel,
    PostCompnentWebxBpsp,
    PostWebxLaravel,
} from "../../../PostCompnent";
import { useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import {decode} from "html-entities";
import moment from "moment";
import {toast, ToastContainer} from "react-toastify";

export function ViewApprovalTransaction(props) {
    const [showBpspChecker, setShowBpspChecker] = useState(false);
    const components=JSON.parse(sessionStorage.getItem("permissions"));
    useEffect(() => {
        if (sessionStorage.getItem("admin_status")=="true"){
            setShowBpspChecker(true);
        }else{
            for (const component of components) {
                if (component.component_name === "Bpsp Checker") {
                    setShowBpspChecker(true);
                    break; // Exit the loop early if found
                }
            }
        }

    }, []);
    function CallInvoice(){

    }

    const appTransactions=(invoice_id)=>{
        let approved_by="";
        if (sessionStorage.getItem("admin")==true){
                approved_by="Admin";
        }else{
                approved_by=sessionStorage.getItem("user_name");
        }
        let formData = new FormData(); //formdata object
        formData.append('invoice_id',invoice_id);
        formData.append('approved_by',approved_by);

        console.log(invoice_id)

        PostWebxLaravel("bpsp/approve-invoice", formData)
            .then((response) => {
                console.log(response.data.success);

                if (response.data.success == 200) {
                    toast(response.data.message); // Call toast for status 0
                }
                props.loadSupplierInvoices()
                props.onHide()
            })
            .catch((error) => {
                console.error("Error occurred:", error);
            });
    }

    const decTransactions=(invoice_id)=>{


        let approved_by="";
        if (sessionStorage.getItem("admin")==true){
            approved_by="Admin";
        }else{
            approved_by=sessionStorage.getItem("user_name");
        }
        let formData = new FormData(); //formdata object
        formData.append('invoice_id',invoice_id);
        formData.append('approved_by',approved_by);

        console.log(invoice_id)

        PostWebxLaravel("bpsp/decline-invoice", formData)
            .then((response) => {

                if (response.data.success == 200) {
                    toast(response.data.message); // Call toast for status 0
                }
                props.loadSupplierInvoices()
                props.onHide()

            })
            .catch((error) => {
                console.error("Error occurred:", error);
            });

    }




    let currency_id = "LKR";
    let invoiceDetail=props.invoiceDetail
    let invoiceLineItems=props.invoiceLineItems;
    let paymentData=props.paymentData;
   // let components=JSON.parse(sessionStorage.getItem("permissions"));


    return (
        <>

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="activation-modal"
                style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}

            >
                <Modal.Body className="modal-type-2">
                    <div
                        className="colse-mdl"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={props.onHide}
                    >
                        X
                    </div>


                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 lft">
                                <h3 className="tdh"></h3>

                                <div className="col-12 tdm-in">
                                    <h6>Transaction Value</h6>
                                    <h3 className="tdh">
                                        <NumberFormat
                                            value={paymentData.transaction_amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"LKR "}
                                        />
                                    </h3>

                                    <h6>Handling Fee</h6>
                                    <h5 className="sh">
                                        {parseFloat(invoiceDetail.supplier_fee)==0.00?(''):(   <NumberFormat
                                            value={paymentData.supplier_fee}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"LKR "}
                                        />)}
                                        {parseFloat(invoiceDetail.merchant_fee)==0.00?(''):(   <NumberFormat
                                            value={invoiceDetail.merchant_fee}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"LKR "}
                                        />)}
                                    </h5>


                                    <h6>Transaction Date</h6>
                                    <h3 className="tdh">
                                        {moment(invoiceDetail.payment_date	).format("DD-MM-YYYY")}
                                    </h3>


                                </div>

                                {/*  <div className="sco-share">
                  <WhatsappShareButton url="www.google.com">
                    <WhatsappIcon size={40} logoFillColor="white" />
                  </WhatsappShareButton>

                  <ViberShareButton url="www.facebook.com">
                    <ViberIcon size={40} logoFillColor="white" />
                  </ViberShareButton>
                </div>
                <button type="button" className="nxt-btn">
                  SHARE RECEIPT &nbsp;&nbsp;
                  <i className="fas fa-envelope" aria-hidden="true"></i>
                </button>*/}
                                <ToastContainer/>
                                {
                                    showBpspChecker && (
                                        <>
                                        <button type="button" className="nxt-btn" style={{backgroundColor:"green"}} onClick={()=>appTransactions(invoiceDetail.id)}>
                                            Approve &nbsp;&nbsp;
                                            <i className="far fa-check-circle"></i>
                                        </button>
                                        <button type="button" className="nxt-btn" style={{backgroundColor:"red"}} onClick={()=>decTransactions(invoiceDetail.id)}>
                                    Decline &nbsp;&nbsp;
                                    <i className="far fa-times-circle"></i>
                                    </button>
                                        </>
                                    )
                                }

                            </div>
                            <div className="col-md-8 rht">
                                <div className="row">


                                    <div className="col-12 details-box d-inv-box">
                                        <div className="col-12 dbx-in">
                                            <h3>Receipt </h3>

                                            <div className="row row_clr dit-in">
                                                <div className="col-md-8 lht">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <p style={{
                                                                font: "10px/12px SFPD Bold",
                                                                letterSpacing: "0.13px",
                                                                color: "#292926",
                                                                marginBottom: "10px"
                                                            }}>Invoice Reference</p>
                                                            <h4 className="total-num" style={{font: "16px/14px SFPD Bold",
                                                                letterSpacing: 0,
                                                                color: "#77b53c"}}>
                                                                {props.invoiceReference}
                                                            </h4>
                                                        </div>



                                                    </div>
                                                </div>
                                                <div className="col-md-4 rhs">


                                                   {/* <p>Total Payable</p>
                                                    <h4 className="total-num">
                                                        <NumberFormat
                                                            value={invoiceDetail.transaction_amount}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            prefix={"LKR "}
                                                        />
                                                    </h4>*/}
                                                </div>
                                                <div className="col-sm-12 rht img-box p-0">
                                                    <div className="rg-container rg-container-type-2">
                                                        <div className="table-outer">
                                                            <table
                                                                className="transactions-table invoice-table tt-type-2"
                                                                summary="Hed"
                                                            >
                                                                <thead>
                                                                <tr>
                                                                    <th className="">#</th>
                                                                    <th className="">Items</th>
                                                                    <th className="">Attachment</th>
                                                                    <th className=""></th>
                                                                    <th className="">Amount</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {invoiceLineItems && invoiceLineItems.length
                                                                    ? invoiceLineItems.map((lineitem, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>{lineitem.description}</td>
                                                                                <td>
                                                                                    {lineitem.attachment!==null?(
                                                                                            <a
                                                                                                href={
                                                                                                    myConfig.laravelWeb +
                                                                                                    "uploads/bpsp_invoice_line_items/" +
                                                                                                    lineitem.attachment
                                                                                                }
                                                                                                target="_blank"
                                                                                            >
                                                                                                {" "}
                                                                                                <i
                                                                                                    className="fa fa-file-pdf-o"
                                                                                                    aria-hidden="true"
                                                                                                    style={{ color: "red" }}
                                                                                                ></i>
                                                                                            </a>
                                                                                        ):
                                                                                        ( '')}

                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <NumberFormat
                                                                                        value={lineitem.price}
                                                                                        displayType={"text"}
                                                                                        thousandSeparator={true}
                                                                                        prefix={"LKR "}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : ""}

                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>SubTotal</td>
                                                                    <td>
                                                                        <NumberFormat
                                                                            value={paymentData.transaction_amount}
                                                                            displayType={"text"}
                                                                            thousandSeparator={true}
                                                                            prefix={"LKR "}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>Handling Fee {paymentData.supplier_fee==0.00?(''):('(Supplier Fee) ')}  {paymentData.merchant_fee==0.00?(''):('(Merchant Fee) ')}</td>
                                                                    <td>{paymentData.supplier_fee==0.00?(''):(   <NumberFormat
                                                                        value={paymentData.supplier_fee}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        prefix={"LKR "}
                                                                    />)}
                                                                        {paymentData.merchant_fee==0.00?(''):(   <NumberFormat
                                                                            value={paymentData.merchant_fee}
                                                                            displayType={"text"}
                                                                            thousandSeparator={true}
                                                                            prefix={"LKR "}
                                                                        />)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>Total Payable</td>
                                                                    <td> <NumberFormat
                                                                        value={paymentData.settlement_amount}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        prefix={"LKR "}
                                                                    /></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {/* <div className="tac">
                              <h6>Attachments</h6>
                              <p></p>
                            </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <button ref={props.clickIndividualInvoice}  onClick={CallInvoice} style={{display:"none"}}></button>
        </>
    );
}
