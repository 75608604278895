import React, {useEffect, useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import NumberFormat from "react-number-format";
import moment from "moment";
import {GetWebxLaravel, PostCompnentWebx, PostCompnentWebxBpsp, PostWebxLaravel} from "../PostCompnent";
import Switch from "react-switch";
import {PaginatePaymentLinkList} from "./PaginatePaymentLinkList";
import {SupportButton} from "../SupportButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Navigation} from "../Navigation";
import {TopBar} from "../TopBar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import QRCode from "qrcode.react";
import {ToastContainer} from "react-toastify";
import {
    FacebookIcon,
    FacebookShareButton, TelegramIcon, TelegramShareButton,
    TwitterIcon,
    TwitterShareButton, ViberIcon, ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Footer} from "../Footer";
import {Link, Navigate} from "react-router-dom";
import {CSVLink} from "react-csv";
import {Redirect} from "react-router";
import { Modal, Button ,Form } from 'react-bootstrap';



export function PaymentLinkListTransactions(props) {
    const zeroPad = (num, places) => String(num).padStart(places, '0')
    const [bankID, setBankId] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [offsetLink, setOffsetLink] = useState(0);
    const [perPageLink, setPerPageLink] = useState(10);
    const [currentPageLink, setCurrentPageLink] = useState(0);
    const [pageCountLink, setPageCountLink] = useState(0);
    const [linkDataRender, setLinkDataRender] = useState([]);
    const [lkrTransactionTotal, setLkrTransactionTotal] = useState(0);
    const [usdTransactionTotal, setUsdTransactionTotal] = useState(0);
    const [gbpTranasactionTotal, setGbpTransactionTotal] = useState(0);
    const [lkrRefundTotal, setLkrRefundTotal] = useState(0);
    const [usdRefundTotal, setUsdRefundTotal] = useState(0);
    const [linkDataAll, setLinkDataAll] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [csvDataLinks, setCsvDataLinks] = useState([]);
    const [checked, setChecked] = React.useState(false);
    const [list, setList] = useState(0);

    const [lkrTransactionTotalPos, setlkrTransactionTotalPos] = useState(0);
    const [usdTransactionTotalPos, setusdTransactionTotalPos] = useState(0);

    const [lkrTransactionNetTotalPos, setlkrTransactionNetTotalPos] = useState(0);
    const [usdTransactionNetTotalPos, setusdTransactionNetTotalPos] = useState(0);

    const [tranasactionCount, setTransactionCount] = useState({
        approved_count: 0,
        pending_count: 0,
        no_count:0,
        total_amount: 0,
    });
    const [subUserList, setSubUserList] = useState(0);
    const [formDataFilter, setFormDataFilter] = useState({
        payment_link_description: '',
        transaction_status: '',
        customer_name: '',
        customer_phone_number: '',
        payment_link_reference: '',
        customer_created_by: '',
        completed_by:'',
        reference_number:'',
        sort_order: 'desc',  // Default sort order
    });

    const [isCleared, setIsCleared] = useState(false);
    const [modalShows, setModalShows] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState("");
const [errorMessage, setErrorMessage] = useState("");
const [selectedOrderId, setSelectedOrderId] = useState(null);
const [referenceError, setReferenceError] = useState(''); // State to handle error message
const [sortColumn, setSortColumn] = useState('transaction_date');  // Default to 'transaction_date'
const [sortOrder, setSortOrder] = useState('desc');




    useEffect(() => {

        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {
        } else {
            setRedirect(true)

        }

    }, []);

    useEffect(() => {
        if (redirect == true) {
            LogOut();

        }
        //setRedirect(false)

    }, [redirect]);

    const LogOut = () => {
        window.location.href = '/login';


    };


    useEffect(() => {

        getPaymentList();

    }, []);
    useEffect(() => {
        console.log("Modal Shows Status:", modalShows); // Will log whenever modalShows changes
    }, [modalShows]); 

    useEffect(() => {
        getPaymentList();
        setIsCleared(false); // Reset the flag
    }, [isCleared]);

    useEffect(() => {
        generateTable();
        generateCSV();
        handleCheckedItems();
    }, [linkDataAll, offsetLink, currentPageLink]);


    useEffect(() => {
        geTotals();
    }, []);

    useEffect(() => {
        getUserList();
    }, []);

    useEffect(() => {
        getTransactionCount();
    }, []);
  // Function to toggle the sort order
  const toggleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
};
    const getPaymentList = (e) => {
        let formData = new FormData();    //formdata object
       // console.log('FormData:', formData);
        formData.append('merchant_id', sessionStorage.getItem("merchant_id"));

        if (formDataFilter.payment_link_description) {
            formData.append('payment_link_description', formDataFilter.payment_link_description);
        }

        if (formDataFilter.payment_link_reference) {
            formData.append('payment_link_reference', formDataFilter.payment_link_reference);
        }

        if (formDataFilter.transaction_status) {
            formData.append('transaction_status', formDataFilter.transaction_status);
            console.log('Selected Transaction Status:', formDataFilter.transaction_status);

        }

        if (formDataFilter.customer_name) {
            formData.append('customer_name', formDataFilter.customer_name);
        }

        if (formDataFilter.customer_phone_number) {
            formData.append('customer_phone_number', formDataFilter.customer_phone_number);
        }
        if (formDataFilter.reference_number) {
           // console.log('Transaction_reference_number value:', formDataFilter.Transaction_reference_number);  // Log value to console
            formData.append('reference_number', formDataFilter.reference_number);
        }
        if (formDataFilter.customer_created_by) {
            formData.append('customer_created_by', formDataFilter.customer_created_by);
        }
        if (formDataFilter.completed_by) {
            formData.append('completed_by', formDataFilter.completed_by);
        }
       // formData.append('sort_order', formDataFilter.sort_order);
// Append sorting options
        formData.append('sort_order', formDataFilter.sort_order || 'desc');
        formData.append('sort_column', formDataFilter.sort_column || 'wbx_link_data.created_at'); // Default sorting

        PostWebxLaravel("links/get-payment-link-list", formData).then((response) => {
           console.log('API Response:', response.data.link_data);  // Check the structure here
           //console.log('Full Response:', response);  // Log the full response object to see the structure
            setLinkDataAll(response.data.link_data);
        });
    }

    const geTotals = (e) => {
        let formData = new FormData();    //formdata object
        formData.append('merchant_id', sessionStorage.getItem("merchant_id"));

        PostCompnentWebx("getStorePosTransactions", formData).then(response => {

            const lkrTotal = response.data
                .filter(transaction => transaction.processing_currency_id == 5 || transaction.processing_currency_id == "" )
                .reduce((total, transaction) => parseFloat(total) + parseFloat(transaction.amount), 0);

            // Calculate the total for USD transactions (processing_currency_id === 2)
            const usdTotal = response.data
                .filter(transaction => transaction.processing_currency_id == 2)
                .reduce((total, transaction) => parseFloat(total) + parseFloat(transaction.amount), 0);

            const lkrTotalNet = response.data
                .filter(transaction => transaction.processing_currency_id == 5 || transaction.processing_currency_id == "")
                .reduce((total, transaction) => parseFloat(total) + parseFloat(transaction.net_amount), 0);

            // Calculate the total for USD transactions (processing_currency_id === 2)
            const usdTotalNet = response.data
                .filter(transaction => transaction.processing_currency_id == 2)
                .reduce((total, transaction) => parseFloat(total) + parseFloat(transaction.net_amount), 0);


            // Update the state with the totals
            setlkrTransactionTotalPos(lkrTotal)
            setusdTransactionTotalPos(usdTotal)
            setlkrTransactionNetTotalPos(lkrTotalNet)
            setusdTransactionNetTotalPos(usdTotalNet)

        });

        PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {


            setLkrTransactionTotal(response.data.totals.lkr_transaction_total)
            setUsdTransactionTotal(response.data.totals.usd_transaction_total)
            setGbpTransactionTotal(response.data.totals.gbp_transaction_total)
            setLkrRefundTotal(response.data.totals.lkr_refund_total)
            setUsdRefundTotal(response.data.totals.usd_refund_total)
        });
    }


    const onChanges = (e) => {
        const {name, value} = e.target;
       // console.log('Selected Value:', value);

        console.log(name)
        setFormDataFilter({
            ...formDataFilter,
            [name]: value
        });

    }


    // Handler for checkbox changes
//     const handleChange = (id) => (event) => {

//         setCheckedItems(prevState => ({
//             ...prevState,
//             [id]: event.target.checked,
//         }));

//         let complete_process = event.target.checked ? 1 : 0;

//         let order_id = id;
//         //console.log("Modal Show Status:", modalShows); // Check if this is being updated
//   // Open modal when the checkbox is checked
//   if (event.target.checked) {
//     setModalShows(true); // Open modal
//    // console.log("Modal Show Status:", modalShows); // Check if this is being updated
// }

//         let formData = new FormData();    //formdata object
//         formData.append('merchant_id', sessionStorage.getItem("merchant_id"));
//         formData.append('complete_process', complete_process);
//         formData.append('order_id', order_id);

//         PostWebxLaravel("links/update-complete-status", formData).then(response => {
//             getPaymentList();
//         });

//     };
// 🟢 Handle Checkbox Click (Open Modal, Store ID)
// const handleChange = (id) => (event) => {
//     setCheckedItems(prevState => ({
//         ...prevState,
//         [id]: event.target.checked,
//     }));

//     if (event.target.checked) {
//         setSelectedOrderId(id); // Store selected order ID
//         setModalShows(true); // Open modal
//     }
// };

// // 🟢 Handle "Confirm" in Modal (Send API Request)
// const handleConfirm = () => {
//     if (!selectedOrderId) return; // Ensure an order ID is selected

//     let formData = new FormData();
//     formData.append('merchant_id', sessionStorage.getItem("merchant_id"));
//     formData.append('complete_process', 1); // Set process as complete
//     formData.append('order_id', selectedOrderId);

//     PostWebxLaravel("links/update-complete-status", formData).then(response => {
//         getPaymentList(); // Refresh data
//         setModalShows(false); // Close modal after successful update
//         setSelectedOrderId(null); // Reset selected ID
//     });
// };
const handleChange = (id) => (event) => {
    //event.preventDefault(); 
    const isChecked = event.target.checked;
    
    if (isChecked) {
        const userName = sessionStorage.getItem('user_name');
        console.log(userName); 
        // ✅ If checked, open modal but do NOT update state yet
        event.preventDefault();
        setSelectedOrderId(id);
        setModalShows(true);
    }
};

// 🟢 Handle "Confirm" in Modal (Save complete_process = 1)
const handleConfirm = () => {
    if (!selectedOrderId) return;
    if (!referenceNumber) {
        setReferenceError('Reference number cannot be blank');
        return; // Prevent form submission if the field is empty
    }
    updateCompleteStatus(selectedOrderId, 1); // Save to DB
    setCheckedItems(prevState => ({
        ...prevState,
        [selectedOrderId]: true, // Only update checkbox after confirmation
    }));
    setModalShows(false);
    setSelectedOrderId(null);
};

// 🟢 Function to Update `complete_process`
const updateCompleteStatus = (orderId, status) => {
    let formData = new FormData();
    const userName = sessionStorage.getItem('user_name'); // Get full username

    // Split the full name into first and last names
    const firstName = userName.split(' ')[0];
    const lastName = userName.split(' ')[1];

    console.log("Extracted first_name: ", firstName);  // Log the first name
    console.log("Extracted last_name: ", lastName);    // Log the last name
    const username = firstName + ' ' + lastName; // Combine first and last name into a single username

    formData.append('merchant_id', sessionStorage.getItem("merchant_id"));
    formData.append('complete_process', status);
    formData.append('order_id', orderId);
    formData.append('reference_number', referenceNumber); // Add reference number to form data
    // formData.append('first_name', sessionStorage.getItem('user_name').split(' ')[0]); // Extract first name
    // formData.append('last_name', sessionStorage.getItem('user_name').split(' ')[1]);  // Extract last name
    formData.append('username', username);  // Send combined username


    PostWebxLaravel("links/update-complete-status", formData).then(response => {
        // After successful API response, refresh the page
        window.location.reload(); // Reload the page
        getPaymentList(); // Refresh data
    })
    .catch(error => {
        console.error('Error:', error);
    });
       
 
};
    const handleCheckedItems = () => {
    console.log("sstting");
        let updates = {};

        linkDataAll.forEach((item) => {
            if (item.complete_process === 1) {
                updates[item.id] = true;
            }
        });

        setCheckedItems((prevState) => ({
            ...prevState,
            ...updates,
        }));


    };


    const handleChanges = (key, newStatus, list_id) => {
        const updatedLinkData = linkDataAll.map((item, index) =>
            list_id === key ? {...item, complete_process: newStatus ? 1 : 0} : item
        );

        setLinkDataAll(updatedLinkData);

    };

//Old function
    // const generateTable = () => {
    //     const list_data = linkDataAll;
    //      //console.log(list_data);
    //     const slice = list_data.slice(offsetLink, offsetLink + perPageLink);
    //     const zeroPad = (num, places) => String(num).padStart(places, '0');
    //     if (sessionStorage.getItem("school") == 1) {
    //         const trans = list_data && list_data.length ? (slice.map((list, key) =>

    //             <tr className=''>
    //                 {/*   <td>{zeroPad(offsetLink + key+1, 4)}</td>*/}
    //                 <td className=''
    //                     data-title='Invoice ID'>{list.get_customers ? list.get_customers.student_full_name : ''}</td>
    //                 <td className="">{list.get_customers ? list.get_customers.admission_number : ''}</td>
    //                 <td className="">{list.get_customers ? list.get_customers.student_class : ''}</td>

    //                 <td className=''
    //                     data-title='Invoice ID'>{list.get_links_school ? list.get_links_school.payment_reference : ''}</td>
    //                 <td className='' data-title='Invoice ID'>LKR {list.total_amount}</td>
    //                 <td className=''
    //                     data-title='Invoice ID'>{list.get_customers ? list.get_customers.first_name + ' ' + list.get_customers.last_name : ''}</td>

    //                 <td className=''
    //                     data-title='Invoice ID'>{list.get_customers ? list.get_customers.contact_number : ''}</td>


    //                 <td className='' data-title='Invoice ID'>{moment(list.created_at).format('ll')}</td>




    //             {/*    <td className=''
    //                     data-title='Invoice ID'>{list.get_links_school ? list.get_links_school.created_by : ''}</td>*/}
    //                 {/*{list.order_status_id == 2 ? (*/}
    //                 {/*    <td className='' data-title='Invoice ID'><span className="dot green"><i*/}
    //                 {/*        className="fas fa-circle"*/}
    //                 {/*        aria-hidden="true"></i> </span> {list.order_status_id == 2 ? "Approved" : "Pending"}*/}
    //                 {/*    </td>*/}
    //                 {/*) : (*/}
    //                 {/*    <td className='' data-title='Invoice ID'><span className="dot yellow"><i*/}
    //                 {/*        className="fas fa-circle"*/}
    //                 {/*        aria-hidden="true"></i> </span> {list.order_status_id == 2 ? "Approved" : "Pending"}*/}
    //                 {/*    </td>*/}
    //                 {/*)*/}

    //                 {/*}*/}
    //                 {/*<td className='' data-title='Invoice ID'>
    //                     {list.order_status_id == 2 ? (
    //                         <div className="form-check" style={{marginBottom: "22px"}}>

    //                             <label key={list.id}>
    //                                 <input
    //                                     type="checkbox"
    //                                     checked={checkedItems[list.id]} // Convert undefined to false
    //                                     onChange={handleChange(list.id)}
    //                                 />

    //                             </label>
    //                         </div>
    //                     ) : (
    //                         <div className="form-check" style={{marginBottom: "22px"}}>

    //                             <label>
    //                                 <input
    //                                     type="checkbox"
    //                                     disabled
    //                                 />

    //                             </label>
    //                         </div>
    //                     )

    //                     }
    //                 </td>*/}

    //             </tr>)) : (
    //             <tr className=''>
    //                 <td colSpan="10" className='' data-title='#'><strong>No results found, for the selected date
    //                     range</strong></td>
    //             </tr>

    //         )
    //         setPageCountLink(Math.ceil(list_data.length / perPageLink))
    //         setLinkDataRender(trans)
    //     } else {
    //         const trans = list_data && list_data.length ? (slice.map((list, key) =>

    //             <tr className=''>
    //                 {/*   <td>{zeroPad(offsetLink + key+1, 4)}</td>*/}
    //                 <td className='' data-title='Invoice ID'>{list.link_description}</td>
    //                 <td className='' data-title='Invoice ID'>LKR {list.total_amount}</td>
    //               {/*  <td className=''
    //                     data-title='Invoice ID'>{list.get_links ? list.get_links.reference_number : ''}</td>*/}
    //                 <td className=''
    //                     data-title='Invoice ID'>{list.get_customers ? list.get_customers.first_name + ' ' + list.get_customers.last_name : ''}</td>
    //                 <td className=''
    //                     data-title='Invoice ID'>{list.get_customers ? list.get_customers.contact_number : ''}</td>


    //                 <td className='' data-title='Invoice ID'>{moment(list.created_at).format('ll')}</td>
    //                 {list.order_status_id == 2 ? (
    //                     <td className='' data-title='Invoice ID'><span className="dot green"><i
    //                         className="fas fa-circle"
    //                         aria-hidden="true"></i> </span> {list.order_status_id == 2 ? "Approved" : "Pending"}
    //                     </td>
    //                 ) : (
    //                     <td className='' data-title='Invoice ID'><span className="dot yellow"><i
    //                         className="fas fa-circle"
    //                         aria-hidden="true"></i> </span> {list.order_status_id == 2 ? "Approved" : "Pending"}
    //                     </td>
    //                 )

    //                 }


    //                 <td className='' data-title='Invoice ID'>{list.get_links ? list.get_links.created_by : ''}</td>
    //                 <td className='' data-title='Invoice ID'>{list.reference_number ? list.reference_number : 'N/A'}</td> {/* Add reference_number */}
    //                 <td className='' data-title='Invoice ID'>{list.process_completed_by ? list.process_completed_by : 'N/A'}</td> {/* Add process_completed_by */}
    //                 <td className='' data-title='Invoice ID'>
    //                     {list.order_status_id == 2 ? (
    //                         <div className="form-check" style={{marginBottom: "22px"}}>

    //                             <label key={list.id}>
    //                                 <input
    //                                     type="checkbox"
    //                                     checked={checkedItems[list.id]} // Convert undefined to false
    //                                     onChange={handleChange(list.id)}
    //                                 />

    //                             </label>
    //                         </div>
    //                     ) : (
    //                         <div className="form-check" style={{marginBottom: "22px"}}>

    //                             <label>
    //                                 <input
    //                                     type="checkbox"
    //                                     disabled
    //                                 />

    //                             </label>
    //                         </div>
                            
    //                     )

    //                     }
    //                 </td>
         

    //             </tr>)) : (
    //             <tr className=''>
    //                 <td colSpan="10" className='' data-title='#'><strong>No results found, for the selected date
    //                     range</strong></td>
    //             </tr>

    //         )
    //         setPageCountLink(Math.ceil(list_data.length / perPageLink))
    //         setLinkDataRender(trans)
    //     }


    // }
    const generateTable = () => {
        const list_data = linkDataAll;
         //console.log(list_data);
        const slice = list_data.slice(offsetLink, offsetLink + perPageLink);
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        if (sessionStorage.getItem("school") == 1) {
            const trans = list_data && list_data.length ? (slice.map((list, key) =>

                <tr className=''>
                    {/*   <td>{zeroPad(offsetLink + key+1, 4)}</td>*/}
                    <td className=''
                        data-title='Invoice ID'>{list.get_customers ? list.get_customers.student_full_name : ''}</td>
                    <td className="">{list.get_customers ? list.get_customers.admission_number : ''}</td>
                    <td className="">{list.get_customers ? list.get_customers.student_class : ''}</td>

                    <td className=''
                        data-title='Invoice ID'>{list.get_links_school ? list.get_links_school.payment_reference : ''}</td>
                    <td className='' data-title='Invoice ID'>LKR {list.total_amount}</td>
                    <td className=''
                        data-title='Invoice ID'>{list.get_customers ? list.get_customers.first_name + ' ' + list.get_customers.last_name : ''}</td>

                    <td className=''
                        data-title='Invoice ID'>{list.get_customers ? list.get_customers.contact_number : ''}</td>


                    <td className='' data-title='Invoice ID'>{moment(list.created_at).format('ll')}</td>




                {/*    <td className=''
                        data-title='Invoice ID'>{list.get_links_school ? list.get_links_school.created_by : ''}</td>*/}
                    {/*{list.order_status_id == 2 ? (*/}
                    {/*    <td className='' data-title='Invoice ID'><span className="dot green"><i*/}
                    {/*        className="fas fa-circle"*/}
                    {/*        aria-hidden="true"></i> </span> {list.order_status_id == 2 ? "Approved" : "Pending"}*/}
                    {/*    </td>*/}
                    {/*) : (*/}
                    {/*    <td className='' data-title='Invoice ID'><span className="dot yellow"><i*/}
                    {/*        className="fas fa-circle"*/}
                    {/*        aria-hidden="true"></i> </span> {list.order_status_id == 2 ? "Approved" : "Pending"}*/}
                    {/*    </td>*/}
                    {/*)*/}

                    {/*}*/}
                    {/*<td className='' data-title='Invoice ID'>
                        {list.order_status_id == 2 ? (
                            <div className="form-check" style={{marginBottom: "22px"}}>

                                <label key={list.id}>
                                    <input
                                        type="checkbox"
                                        checked={checkedItems[list.id]} // Convert undefined to false
                                        onChange={handleChange(list.id)}
                                    />

                                </label>
                            </div>
                        ) : (
                            <div className="form-check" style={{marginBottom: "22px"}}>

                                <label>
                                    <input
                                        type="checkbox"
                                        disabled
                                    />

                                </label>
                            </div>
                        )

                        }
                    </td>*/}

                </tr>)) : (
                <tr className=''>
                    <td colSpan="10" className='' data-title='#'><strong>No results found, for the selected date
                        range</strong></td>
                </tr>

            )
            setPageCountLink(Math.ceil(list_data.length / perPageLink))
            setLinkDataRender(trans)
        } else {
            const trans = list_data && list_data.length ? (slice.map((list, key) =>

                <tr className=''>
                    {/*   <td>{zeroPad(offsetLink + key+1, 4)}</td>*/}
                    {/* <td className='' data-title='Invoice ID'>{list.link_description}</td> */}
                    <td className='' data-title='Invoice ID'>{list.payment_link_description}</td>
                    <td className='' data-title='Link Amount'>LKR {list.link_amount}</td>
                  {/*  <td className=''
                        data-title='Invoice ID'>{list.get_links ? list.get_links.reference_number : ''}</td>*/}
                  <td className='' data-title='Invoice ID'>
                        {list.first_name && list.last_name ? list.first_name + ' ' + list.last_name : 'N/A'}
                </td>

                    <td className=''
                        data-title='Invoice ID'>{list.contact_number? list.contact_number : 'N/A'}</td>


                    <td className='' data-title='Invoice ID'>{moment(list.link_data_created_at).format('ll')}</td>
                    <td className='' data-title='Invoice ID'>
                     {list.order_created_at ? moment(list.order_created_at).format('ll') : 'N/A'}
                      </td>
                    {/* {list.order_status_id == 2 ? (
                        <td className='' data-title='Invoice ID'><span className="dot green"><i
                            className="fas fa-circle"
                            aria-hidden="true"></i> </span> {list.order_status_id == 2 ? "Approved" : "Pending"}
                        </td>
                    ) : (
                        <td className='' data-title='Invoice ID'><span className="dot yellow"><i
                            className="fas fa-circle"
                            aria-hidden="true"></i> </span> {list.order_status_id == 2 ? "Approved" : "Pending"}
                        </td>
                    )

                    } */}
<td className='' data-title='Invoice ID'>
    {list.order_status_id === 2 ? (
        <>
            <span className="dot green">
                <i className="fas fa-circle" aria-hidden="true"></i>
            </span> 
            Approved
        </>
    ) : list.order_status_id === 1 ? (
        <>
            <span className="dot yellow">
                <i className="fas fa-circle" aria-hidden="true"></i>
            </span>
            Pending
        </>
    ) : list.order_status_id === null ? (
        <>
            <span className="dot gray">
                <i className="fas fa-circle" aria-hidden="true"></i>
            </span>
            N/A
        </>
    ) : (
        <>
            <span className="dot gray">
                <i className="fas fa-circle" aria-hidden="true"></i>
            </span>
            Unknown
        </>
    )}
</td>


                    <td className='' data-title='Invoice ID'>{list.created_by}</td>
                    <td className='' data-title='Invoice ID'>{list.reference_number ? list.reference_number : 'N/A'}</td> {/* Add reference_number */}
                    <td className='' data-title='Invoice ID'>{list.order_reference ? list.order_reference : 'N/A'}</td> {/* Add reference_number */}
                    <td className='' data-title='Invoice ID'>{list.process_completed_by ? list.process_completed_by : 'N/A'}</td> {/* Add process_completed_by */}
                    <td className='' data-title='Invoice ID'>
                        {list.order_status_id == 2 ? (
                            <div className="form-check" style={{marginBottom: "22px"}}>

                                <label key={list.id}>
                                    <input
                                        type="checkbox"
                                        checked={checkedItems[list.id]} // Convert undefined to false
                                        onChange={handleChange(list.id)}
                                    />

                                </label>
                            </div>
                        ) : (
                            <div className="form-check" style={{marginBottom: "22px"}}>

                                <label>
                                    <input
                                        type="checkbox"
                                        disabled
                                    />

                                </label>
                            </div>
                            
                        )

                        }
                    </td>
         

                </tr>)) : (
                <tr className=''>
                    <td colSpan="10" className='' data-title='#'><strong>No results found, for the selected date
                        range</strong></td>
                </tr>

            )
            setPageCountLink(Math.ceil(list_data.length / perPageLink))
            setLinkDataRender(trans)
        }


    }


    const generateCSV = () => {
        const csvData = [];
        csvData.push(
            ["", "", "", "", "LINK REPORT - ", ""]
        );
        csvData.push(
            []
        );
        if (sessionStorage.getItem("school") == 1) {
            csvData.push(
                ["STUDENT NAME", "ADMISSION NUMBER", "CLASS", "PAYMENT REFERENCE", "TOTAL PAYMENT", "CUSTOMER NAME", "CUSTOMER PHONE NUMBER", "DATE OF TRANSACTION"]
            );
        }else{
            csvData.push(
                ["PAYMENT LINK DESCRIPTION", "TOTAL PAYMENT", "CUSTOMER NAME", "CUSTOMER PHONE NUMBER", "DATE OF TRANSACTION"]
            );
        }
        const list_data = linkDataAll;
        list_data.map((value, index) => {
            if (sessionStorage.getItem("school") == 1) {
                csvData.push([
                    value.get_customers ? value.get_customers.student_full_name : '',
                    value.get_customers ? value.get_customers.admission_number : '',
                    value.get_customers ? value.get_customers.student_class : '',
                    value.get_links_school ? value.get_links_school.payment_reference : '',
                    value.total_amount,

                    value.get_customers ? value.get_customers.first_name + ' ' + value.get_customers.last_name : '',
                    value.get_customers ? value.get_customers.contact_number : '',
                    moment(value.created_at).format('ll'),
                ]);
            }else{
                csvData.push([
                    // value.payment_link_description,
                    // value.link_amount,
                    // value.first_name + ' ' + value.last_name,
                    // value.contact_number,
                    // moment(value.created_at).format('ll'),
                    value.payment_link_description ?? "N/A",
                    value.link_amount ?? "N/A",
                    (value.first_name && value.last_name) ? value.first_name + " " + value.last_name : "N/A",
                    value.contact_number ?? "N/A",
                    value.created_at ? moment(value.created_at).format('ll') : "N/A",
                ]);
            }


        });
        setCsvDataLinks(csvData)

    }
    const getUserList = () => {

        GetWebxLaravel("sub_users/get_sub_users/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            setSubUserList(response.data);
        });
    }

    const getTransactionCount = () => {
        let formData = new FormData();    //formdata object
        formData.append('merchant_id', sessionStorage.getItem("merchant_id"));
        PostWebxLaravel("links/get-transaction-count", formData).then(response => {
            setTransactionCount({
                approved_count: response.data.approved_transactions,
                pending_count: response.data.pending_transactions,
                no_count: response.data.no_transaction_made,
                total_amount: response.data.total_amount,
            });
        });
    }
    const clearSearch = () => {
        setFormDataFilter({
            payment_link_description: '',
            transaction_status: '',
            customer_name: '',
            customer_phone_number: '',
            payment_link_reference: '',
            customer_created_by: '',
            completed_by:'',
            reference_number:'',
        });
        setIsCleared(true)

    }


    return (
        <>
            <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                <div id="turn-box"></div>
                <Navigation pathname="transactions"/>
                <div id="main-content" className="row row_clr dashboard-live-mode">
                    <TopBar pathname="transactions"/>

                    <div className="col-12 transaction-top">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item mb-2">
                                <Link to="../transactions" className="nav-link " id="tt-tab" data-toggle="tab"
                                      role="tab"
                                      aria-controls="tt" aria-selected="true"><span
                                    className="tt">Total Transactions</span>


                                    <span
                                        className="amt">LKR {lkrTransactionTotal}</span>
                                    {
                                        props.lkrTransactionTotal == 0.00 ? '' : <> <span
                                            className="sep">|</span> <span
                                            className="amt">USD {usdTransactionTotal}</span></>
                                    }

                                    {
                                        props.gbpTranasactionTotal == 0.00 ? '' : <> <span
                                            className="sep">|</span> <span
                                            className="amt">GBP {gbpTranasactionTotal}</span></>
                                    }

                                    {/*{
                                        this.state.aud_transaction_total == 0.00 ? '' : <> <span
                                            className="sep">|</span> <span
                                            className="amt">AUD {this.state.aud_transaction_total}</span></>
                                    }*/}

                                </Link>

                            </li>
                            <li className="nav-item mb-2">
                                <Link to="../transaction-refund" className="nav-link" id="tt-tab"
                                      data-toggle="tab" role="tab"
                                      aria-controls="tr" aria-selected="false"><span
                                    className="tt">Total Refunds</span> <span
                                    className="amt">LKR {lkrRefundTotal}</span>
                                    {
                                        props.usdRefundTotal == 0.00 ? '' : <> <span className="sep">|</span>
                                            <span
                                                className="amt">USD {usdRefundTotal}</span></>
                                    }
                                    {/*{
                                        this.state.gbp_refund_total == 0.00 ? '' : <> <span className="sep">|</span>
                                            <span
                                                className="amt">GBP {this.state.gbp_refund_total}</span></>
                                    }
                                    {
                                        this.state.aud_refund_total == 0.00 ? '' : <> <span className="sep">|</span>
                                            <span
                                                className="amt">AUD {this.state.aud_refund_total}</span></>
                                    }
                                    {
                                        this.state.inr_refund_total == 0.00 ? '' : <> <span className="sep">|</span>
                                            <span
                                                className="amt">INR {this.state.inr_refund_total}</span></>
                                    }*/}

                                </Link>
                            </li>

                            <li className="nav-item mb-2">
                                <Link to="../payment-link-transactions" className="nav-link active" id="tt-tab"
                                      data-toggle="tab" role="tab"
                                      aria-controls="tr" aria-selected="false"><span
                                    className="tt"> Payment Link Transactions </span>

                                    {sessionStorage.getItem("school")==1?(
                                        <span className="amt">Approved : {tranasactionCount.approved_count} | Total :
                                              <NumberFormat
                                                  value={(Math.round(tranasactionCount.total_amount * 100) / 100).toFixed(2)}
                                                  displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
                                           </span>
                                    ):(  <span className="amt">Approved : {tranasactionCount.approved_count} | Pending : {tranasactionCount.pending_count}</span>)}


                                </Link>
                            </li>
                            {sessionStorage.getItem("pos_merchant")==1?(
                            <li className="nav-item mb-2">
                                <Link to="../pos-transactions" className="nav-link" id="tt-tab"
                                      data-toggle="tab" role="tab"
                                      aria-controls="tr" aria-selected="false"><span
                                    className="tt">  POS Transactions </span>

                                    {
                                        lkrTransactionTotalPos == 0.00 ? '' : <>
                                                <span className="amt"><NumberFormat
                                                    value={(Math.round(lkrTransactionTotalPos * 100) / 100).toFixed(2)}
                                                    displayType={'text'} thousandSeparator={true} prefix={'Total Amount '}/> </span>
                                            <br/></>
                                    }
                                    {
                                        lkrTransactionNetTotalPos == 0.00 ? '' :<>
                                                <span className="amt"><NumberFormat
                                                    value={(Math.round(lkrTransactionNetTotalPos * 100) / 100).toFixed(2)}
                                                    displayType={'text'} thousandSeparator={true} prefix={'Net Amount '}/> </span></>
                                    }
                                    {
                                        usdTransactionTotalPos == 0.00 ? '' : <> <span className="sep">|</span>
                                            <span
                                                className="amt"><NumberFormat
                                                value={(Math.round(usdTransactionTotalPos * 100) / 100).toFixed(2)}
                                                displayType={'text'} thousandSeparator={true} prefix={'Full USD '}/></span></>
                                    }

                                    {
                                        usdTransactionNetTotalPos == 0.00 ? '' :<>
                                                <span className="amt"><NumberFormat
                                                    value={(Math.round(usdTransactionNetTotalPos * 100) / 100).toFixed(2)}
                                                    displayType={'text'} thousandSeparator={true} prefix={'Net USD '}/> </span></>
                                    }
                                </Link>
                            </li>):('')}
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tt" role="tabpanel"
                                 aria-labelledby="tt-tab">
                                <div className="row row_clr">
                                    <div className="col-12">
                                        <div className="row row_clr tt-ttp">
                                            <form className="col-md-8 col-12 tt-form">
                                                <div className="row row_clr">
                                                    {sessionStorage.getItem("school") == 1?(
                                                      ''
                                                    ):(  <div className="form-group">
                                                        <label htmlFor="">Payment Link Description</label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Payment Link Description"
                                                               name="payment_link_description"
                                                               value={formDataFilter.payment_link_description}
                                                               onChange={onChanges}
                                                        />
                                                    </div>)}


                                                    <div className="form-group">
                                                        <label htmlFor="">Payment Link Reference</label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Payment Link Reference"
                                                               name="payment_link_reference"
                                                               value={formDataFilter.payment_link_reference}
                                                               onChange={onChanges}
                                                            // value={this.state.store_reference}
                                                            // onChange={onChanges}
                                                            /*   onKeyPress={event => {
                                                                   if (event.key === 'Enter') {
                                                                       this.filterList(event)
                                                                   }
                                                               }}*/
                                                        />
                                                    </div>

                                                    {/*<div className="form-group">
                                                        <label htmlFor="">Transaction Status</label>
                                                        <div className="slct">
                                                            <select name="transaction_status" id="c1"
                                                                    className="form-control"
                                                                    value={formDataFilter.transaction_status}

                                                                    onChange={onChanges}>

                                                                <option value="">Select Status</option>
                                                                <option value="2">Approved</option>
                                                                <option value="1">Pending</option>
                                                            </select>
                                                        </div>
                                                    </div>*/}
                                                    <div className="form-group">
                                                        <label htmlFor="">Customer Name</label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Customer Name"
                                                               name="customer_name"
                                                               value={formDataFilter.customer_name}
                                                               onChange={onChanges}
                                                            // value={this.state.store_reference}
                                                            // onChange={onChanges}
                                                            /*   onKeyPress={event => {
                                                                   if (event.key === 'Enter') {
                                                                       this.filterList(event)
                                                                   }
                                                               }}*/
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">Customer Phone Number</label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Customer Phone Number"
                                                               name="customer_phone_number"
                                                               value={formDataFilter.customer_phone_number}
                                                               onChange={onChanges}
                                                            // value={this.state.store_reference}
                                                            // onChange={onChanges}
                                                            /*   onKeyPress={event => {
                                                                   if (event.key === 'Enter') {
                                                                       this.filterList(event)
                                                                   }
                                                               }}*/
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">Refference Numebr</label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Transaction Refference Numebr"
                                                               name="reference_number"
                                                               value={formDataFilter.reference_number}
                                                               onChange={onChanges}
                                                            // value={this.state.store_reference}
                                                            // onChange={onChanges}
                                                            /*   onKeyPress={event => {
                                                                   if (event.key === 'Enter') {
                                                                       this.filterList(event)
                                                                   }
                                                               }}*/
                                                        />
                                                    </div>
                                                    <div className="form-group">
        <label htmlFor="">Order Status</label>
        <select 
            name="transaction_status" 
            id="transaction_status"
            className="form-control"
            value={formDataFilter.transaction_status}
            onChange={onChanges}>
            <option value="">-- Select Status --</option>
            <option value="2">Approved</option>
            <option value="1">Pending</option>
            <option value="NULL">N/A(No any transaction Made)</option>
        </select>
    </div>
                                                    {subUserList && subUserList.length ? (
                                                        <div className="form-group">
                                                            <label htmlFor="">Created By</label>
                                                            <select name="customer_created_by" id="c1"
                                                                    className="form-control"
                                                                    value={formDataFilter.customer_created_by}
                                                                    onChange={onChanges}>

                                                                <option value="">-- Select --</option>
                                                                <option
                                                                    value={sessionStorage.getItem("user_name")}>{sessionStorage.getItem("user_name")}</option>
                                                                {subUserList && subUserList.length ? (
                                                                    subUserList.map((list, key) => {

                                                                        return (
                                                                            <option
                                                                                value={list.first_name + " " + list.last_name}>{list.first_name} {list.last_name}</option>
                                                                        )
                                                                    })
                                                                ) : ('')
                                                                }
                                                            </select>
                                                        </div>) : ('')
                                                    }

 {/* New Input for Completed By */}
 <div className="form-group">
            <label htmlFor="">Completed By</label>
            <input 
                type="text" 
                className="form-control"
                placeholder="Completed By"
                name="completed_by"
                value={formDataFilter.completed_by}
                onChange={onChanges}
            />
        </div>
        {/* <div className="form-group">
            <label htmlFor="">Sort by Date</label>
            <select name="sort_order" id="sort_order" className="form-control" value={formDataFilter.sort_order} onChange={onChanges}>
                <option value="desc">Newest First (Descending)</option>
                <option value="asc">Oldest First (Ascending)</option>
            </select>
        </div> */}
        <div className="form-group">
    <label htmlFor="sort_column">Sort By</label>
    <select 
        name="sort_column" 
        id="sort_column" 
        className="form-control" 
        value={formDataFilter.sort_column} 
        onChange={onChanges}
    >
        <option value="wbx_link_data.created_at">Link Created Date</option>
        <option value="wbx_orders.created_at">Transaction Date</option>
    </select>
</div>

<div className="form-group">
    <label htmlFor="sort_order">Sort Order</label>
    <select 
        name="sort_order" 
        id="sort_order" 
        className="form-control" 
        value={formDataFilter.sort_order} 
        onChange={onChanges}
    >
        <option value="desc">Newest First (Descending)</option>
        <option value="asc">Oldest First (Ascending)</option>
    </select>
</div>
                                                    <div className="form-group">
                                                        <button type="button" className="srch-btn"
                                                                onClick={getPaymentList}
                                                                style={{marginTop: "15px"}}>Search
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="button" className="srch-btn clear-btn"
                                                                onClick={clearSearch}
                                                                style={{marginTop: "15px"}}>Reset/
                                                            <i className="fa fa-refresh" aria-hidden="true"></i>

                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                            {/*   <form className="col-md-4 col-12 tr-form">
                                                <div className="row row_clr">


                                                    <div className="form-group">
                                                        <label htmlFor="">Filters & Reports</label>
                                                        <div className="in table-datepicker">
                                                            <DateRangePicker
                                                                 onEvent={this.handleEvent}
                                                                  onCallback={this.handleCallback}
                                                                   initialSettings={{
                                                                       startDate: moment(this.state.startDate).format('M/DD/YYYY'),
                                                                       endDate: moment(this.state.endDate).format('M/DD/YYYY'),
                                                                       maxDate: moment().format('M/DD/YYYY'),
                                                                       autoApply: true
                                                                   }}

                                                            >
                                                                <button type="button" className="date-btn"><i
                                                                    className="far fa-calendar-alt"></i> 5 Days <i
                                                                    className="fas fa-caret-down"
                                                                    aria-hidden="true"></i></button>
                                                            </DateRangePicker>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">

                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="none"
                                                                             id="settlement-download-dropdown">
                                                                <i className="fas fa-download"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <CSVLink data={[]} style={{
                                                                    textDecoration: "none !important",
                                                                    color: "#000"
                                                                }} className="dropdown-item"
                                                                         filename={"TransactionReport.csv"}>
                                                                    <i className="fas fa-download"></i> CSV Download
                                                                </CSVLink>
                                                                <button type="button"
                                                                         onClick={() => this.exportPDF()}
                                                                        className="dropdown-item"><i
                                                                    className="fas fa-file-pdf"></i>Generate Report
                                                                </button>

                                                            </Dropdown.Menu>

                                                        </Dropdown>


                                                    </div>

                                                </div>
                                            </form>*/}


                                            <form className="col-md-4 col-12 tr-form">
                                                <div className="row row_clr">

                                                    <div className="form-group">

                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="none"
                                                                             id="settlement-download-dropdown">
                                                                <i className="fas fa-download"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <CSVLink data={csvDataLinks} style={{
                                                                    textDecoration: "none !important",
                                                                    color: "#000"
                                                                }} className="dropdown-item"
                                                                         filename={"TransactionReport.csv"}>
                                                                    <i className="fas fa-download"></i> CSV Download
                                                                </CSVLink>
                                                                {/* <button type="button"
                                                                        onClick={() => this.exportPDF()}
                                                                        className="dropdown-item"><i
                                                                    className="fas fa-file-pdf"></i>Generate Report
                                                                </button>*/}

                                                            </Dropdown.Menu>

                                                        </Dropdown>


                                                    </div>


                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <PaginatePaymentLinkList
                                        linkDataRender={linkDataRender}
                                        offsetLink={offsetLink}
                                        perPageLink={perPageLink}
                                        currentPageLink={currentPageLink}
                                        pageCountLink={pageCountLink}
                                        setCurrentPageLink={setCurrentPageLink}
                                        setOffsetLink={setOffsetLink}
                                        generateTable={generateTable}
                                        lkrTransactionTotal={lkrTransactionTotal}
                                        usdTransactionTotal={usdTransactionTotal}
                                        gbpTranasactionTotal={gbpTranasactionTotal}
                                        lkrRefundTotal={lkrRefundTotal}
                                        usdRefundTotal={usdRefundTotal}
                                        toggleSort={toggleSort}
                                        sortOrder={sortOrder}
                                        sortColumn={sortColumn}
                                    />

                                </div>


                            </div>
                            {/* <Modal show={modalShows} onHide={() => setModalShows(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to proceed with this action?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalShows(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setModalShows(false); // Close modal after confirming
                            // Do any additional actions here after confirmation
                        }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal> */}
               <Modal show={modalShows} onHide={() => {setModalShows(false)
                window.location.reload();
               }}>
    <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div>
            Are you sure you want to proceed with this action?
        </div>
        <div className="mt-3">
            <label htmlFor="referenceNumber">Reference Number</label>
            <input
                id="referenceNumber"
                type="text"
                className="form-control"
                value={referenceNumber}
                onChange={(e) => setReferenceNumber(e.target.value)}
                placeholder="Enter reference number"
            />
            {referenceError && <p style={{ color: 'red' }}>{referenceError}</p>}
        </div>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => {
            setModalShows(false);
            window.location.reload();
        }}>
            Close
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
            Confirm
        </Button>
    </Modal.Footer>
</Modal>

                        </div>
                    </div>

                    <Footer/>

                </div>
            </div>


        </>
    );
}
