import Modal from "react-bootstrap/Modal";
import React, { useContext, useState } from "react";
import "../../../../src/css/bootstrap.min.css";
import "../../../../src/css/supplier_modal/master-model.css";
import "../../../../src/css/supplier_modal/screen-model.css";
import "../../../../src/css/supplier_modal/switch.css";
import Nav from "react-bootstrap/Nav";
import Alert from "react-bootstrap/Alert";
import Tab from "react-bootstrap/Tab";
import Badge from "react-bootstrap/Badge";




import { useRef } from "react";
import {CreateTransactionLeftPane} from "../SupplierIncludes/TransactionManagement/CreateTransactionLeftPane";

import {PaymentInformationData} from "./PaymentInformationData";
import {TokenizedCards} from "./TokenizedCards";

export function TokenizedPayment(props) {


    const [mdrPercentage, setMdrPercentage] = useState(sessionStorage.getItem("bpsp_mdr_rate"));
    const [subTotal, setTotalValue] = useState(0.00);
    const [transactionAmount, setTransactionAmount] = useState(0.00);
    const [settlementAmount, setSettlementAmount] = useState(0.00);
    const [merchantFee, setMerchantFee] = useState(0.00);
    const [supplierFee, setSupplierFee] = useState(0.00);
    const [convenienceFee, setconvenienceFee] = useState(0.00);

    const [invoiceId, setInvoiceId] = useState("");
    const [sessionGenerate,setSessionGenerate]=useState("0");
    const myfunc=useRef();



    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="activation-modal"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body className="modal-type-2">
                    <div
                        className="colse-mdl"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={props.onHide}
                    >
                        X
                    </div>

                    <Tab.Container className="container-fluid" defaultActiveKey="second"  activeKey="second">
                        <div className="row">

                            <div className="col-md-12 rht">
                                <Tab.Content className="tab-content" id="v-pills-tabContent">

                                    <PaymentInformationData
                                        productList={props.productList}
                                        convinceFeeAcceptedBy={props.convinceFeeAcceptedBy}
                                        subTotal={subTotal}
                                        transactionAmount={transactionAmount}
                                        settlementAmount={settlementAmount}
                                        merchantFee={merchantFee}
                                        supplierFee={supplierFee}
                                        convenienceFee={convenienceFee}
                                        invoiceId={invoiceId}
                                        mdrPercentage={mdrPercentage}
                                        hideModal={props.onHide}
                                        supplierData={props.supplierData}

                                        setTransactionAmount={setTransactionAmount}
                                        setSettlementAmount={setSettlementAmount}
                                        setMerchantFee={setMerchantFee}
                                        setSupplierFee={setSupplierFee}
                                        setconvenienceFee={setconvenienceFee}
                                        setTotalValue={setTotalValue}
                                        setConvinceFeeAcceptedBy={props.setConvinceFeeAcceptedBy}

                                        sessionGenerate={sessionGenerate}
                                        setSessionGenerate={setSessionGenerate}
                                    />

                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
        </>
    );
}
